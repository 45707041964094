import i18n from "@/plugins/vue-i18n";

const CheckEmail = () => import(/* webpackChunkName: "auth" */ "@/views/auth/check-email");

const checkEmail = [
  {
    path: "/check-email",
    name: "CheckEmail",
    component: CheckEmail,
    meta: {
      title: i18n.t("title.checkYourEmail"),
    },
  },
];

export default checkEmail;
