import { getRandomId } from "@/mixins/global.mixin";

export default {
  namespaced: true,

  state: {
    appKey: getRandomId(),
  },

  mutations: {
    SET_APP_KEY(state, key) {
      state.appKey = key;
    },
  },

  actions: {
    refreshAppKey(context) {
      const key = getRandomId();

      context.commit("SET_APP_KEY", key);
    },
  },
};
