<template>
  <div class="input-wrapper" :class="inputWrapperClasses">
    <label v-if="label.length" class="label" :for="id" @click="onClickLabel">{{ label }}</label>

    <vue-tel-input
      ref="tel"
      :model-value="phone"
      :auto-format="false"
      :auto-default-country="false"
      :input-options="inputOptions"
      :dropdown-options="dropdownOptions"
      :preferred-countries="preferredCountries"
      :default-country="defaultCountry"
      :ignored-countries="ignoredCountries"
      :mode="mode"
      :only-countries="onlyCountries"
      valid-characters-only
      :disabled="disabled"
      class="input"
      @on-input="input"
      @country-changed="input()"
    >
      <template #arrow-icon>
        <IconSvg name="UnfoldMore" size="xs" color="pale-sky" class="arrow-icon" />
      </template>
    </vue-tel-input>

    <ErrorTooltip v-if="error" :error="error" />
  </div>
</template>

<script setup>
import { VueTelInput } from "vue-tel-input";
VueTelInput.compatConfig = { MODE: 3 };

import { IconSvg } from "@/components";
import ErrorTooltip from "../ErrorTooltip";
import ValidationService from "@/services/validation.service";
import { defineProps, ref, computed, watch, defineEmits } from "vue";
import { getRandomId } from "@/mixins/global.mixin";

const { INTEGER_REG_EXP } = ValidationService;

const emit = defineEmits(["input"]);

const tel = ref();

const props = defineProps({
  value: {
    type: String,
    default: "",
    required: true,
  },

  label: {
    type: String,
    default: "",
  },

  size: {
    type: String,
    default: "md",
  },

  placeholder: {
    type: String,
    default: "",
  },

  preferredCountries: {
    type: Array,
    default: () => ["US"],
  },

  defaultCountry: {
    type: String,
    default: "US",
  },

  maxLength: {
    type: Number,
    default: 13,
  },

  mode: {
    type: String,
    default: "national",
  },

  onlyCountries: {
    type: Array,
    default: () => [],
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  error: {
    type: String,
    default: "",
  },

  variant: {
    type: String,
    default: "regular",
  },

  /**
   * Set required.
   */
  required: {
    type: Boolean,
    default: false,
  },
});

const phone = ref("");

const id = computed(() => getRandomId());
const ignoredCountries = ["RU", "BY"];

const inputOptions = computed(() => {
  return {
    id: id,
    type: "tel",
    placeholder: props.placeholder,
    maxlength: props.maxLength,
    showDialCode: false,
    autocomplete: "off",
  };
});

const dropdownOptions = computed(() => {
  return {
    showFlags: true,
    showDialCodeInList: true,
    showDialCodeInSelection: true,
  };
});

const inputWrapperClasses = computed(() => {
  const variant = `input-${props.variant}`;
  const size = `input-${props.variant}-size-${props.size}`;
  const classes = {
    "input-error": props.error,
    "input-required": props.required,
  };

  return [variant, size, classes];
});

const onChangeValue = (value) => {
  const isNotEmptyPhone = !!phone.value.length;

  phone.value = value;

  if (isNotEmptyPhone) {
    const { phoneObject } = tel.value;
    const { nationalNumber } = phoneObject;

    if (nationalNumber) {
      tel.value.emitInput(nationalNumber.toString(), phoneObject);
      phone.value = nationalNumber.toString();
    }
  }
};

watch(() => props.value, onChangeValue, {
  immediate: true,
});

const input = (value = "") => {
  const { phoneObject } = tel.value;

  const isNotEmptyPhoneObject = !!Object.keys(phoneObject).length;

  if (value) {
    phone.value = transformValue(value, INTEGER_REG_EXP);
    tel.value.phone = transformValue(value, INTEGER_REG_EXP);
  }

  if (isNotEmptyPhoneObject) {
    const { nationalNumber } = phoneObject;

    if (nationalNumber) {
      tel.value.phone = nationalNumber.toString();
    }

    const { number } = tel.value.phoneObject;

    if (number !== props.value) {
      emit("input", number);
    }
  }
};

watch(
  () => tel.value?.activeCountryCode,
  () => {
    if (!tel.value.activeCountryCode) {
      tel.value.activeCountryCode = props.defaultCountry;
    }
  }
);

const transformValue = (value, exp) => {
  const regExp = new RegExp(exp, "ig");
  const matches = String(value).match(regExp);

  return matches ? matches.join("") : "";
};

const onClickLabel = () => {
  tel.value.focus();
};
</script>

<style lang="css" scoped>
@import "~vue-tel-input/vue-tel-input.css";
</style>

<style lang="postcss" scoped>
.input-wrapper {
  @apply relative;

  .label {
    @apply text-sm font-bold text-pale-sky;
    @apply mb-1.5 block;
  }

  .input:deep() {
    @apply rounded-[0.9375rem] border border-solid border-mystic;

    .arrow-icon {
      @apply ml-1;
    }

    .vti__dropdown {
      @apply rounded-l-[0.9375rem];
      @apply bg-athens-gray-1;

      .vti__selection {
        @apply text-sm;
        @apply select-none;

        .vti__country-code {
          @apply text-ebony-clay;
        }
      }
    }

    .vti__input {
      @apply rounded-r-[0.9375rem];
      @apply text-ebony-clay;

      &:focus-within {
        @apply ring-0;
        @apply !bg-white;
        @apply transition-colors duration-300;
      }

      &:hover {
        @apply bg-blaze-orange bg-opacity-5;
        @apply transition-colors duration-300;
      }

      &::placeholder {
        @apply text-oslo-gray;
        @apply select-none;
      }
    }

    .vti__dropdown-list {
      @apply rounded-lg border border-solid border-mystic shadow-outer;
      @apply w-fit max-w-xs sm:max-w-max md:max-w-sm lg:max-w-max;
      @apply top-full h-fit;
      @apply z-10;

      &:focus-visible {
        @apply outline-none;
      }
    }

    .vti__dropdown-item {
      @apply py-2.5 px-4;
      @apply whitespace-nowrap;

      strong,
      span {
        @apply font-normal;
      }

      &:hover {
        @apply bg-gray-100;
      }

      &:active {
        @apply bg-gray-200 font-medium;

        strong {
          @apply bg-gray-200 font-medium;
        }
      }

      &.last-preferred {
        @apply border-gray-300;
      }
    }

    &:focus-within {
      @apply border border-solid border-jaffa-1;
      @apply shadow-none;
    }
  }
}

.input-regular {
  &-size-md {
    .input:deep() {
      .vti__input {
        @apply py-[0.688rem] px-[0.6rem];
        @apply text-sm;
      }
    }
  }
}

.input-inner {
  &-size-md {
    .input:deep() {
      .vti__input {
        @apply py-[0.688rem] px-[0.6rem];
        @apply text-sm;
      }
    }
  }

  &-size-lg {
    .input:deep() {
      .vti__input {
        @apply p-[1.219rem];
        @apply text-base;
      }
    }
  }
}

.input-required {
  &.input-regular {
    .label {
      &:after {
        @apply content-['*'];
        @apply ml-1 font-medium text-thunderbird;
        @apply z-[1];
      }
    }
  }

  &.input-inner {
    &:before {
      @apply content-['*'];
      @apply text-xs;
      @apply mr-1 font-medium text-thunderbird;
      @apply absolute;
      @apply z-[1];
    }

    &-size-md {
      &:before {
        @apply left-[0.563rem] top-[0.2rem];
      }
    }

    &-size-lg {
      &:before {
        @apply left-[0.563rem] top-[0.375rem];
      }
    }
  }
}

.input-error {
  .input:deep() {
    @apply !border-thunderbird;

    .vti__input {
      &:hover,
      &:focus {
        @apply bg-sauvignon text-thunderbird;
      }
    }
  }
}
</style>
