export default {
  namespaced: true,

  state: {
    isLoading: false,
    requestsTimeout: null,
    requestsQuare: [],
  },

  mutations: {
    SET_LOADING_ON: (state, apiEndpoint) => {
      if (process.env.VUE_APP_ENV !== "test") {
        state.requestsQuare.push(apiEndpoint);

        state.isLoading = true;

        clearTimeout(state.requestsTimeout);
      }
    },

    SET_LOADING_OFF: (state, apiEndpoint) => {
      if (process.env.VUE_APP_ENV !== "test") {
        state.requestsQuare.forEach((item, index) => {
          if (item === apiEndpoint) {
            state.requestsQuare.splice(index, 1);
          }
        });

        state.requestsTimeout = setTimeout(() => {
          if (!state.requestsQuare.length) {
            state.isLoading = false;
          }
        }, 150);
      }
    },
  },

  getters: {
    isLoadingStatus(state) {
      return state.isLoading;
    },
  },
};
