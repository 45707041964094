import ApiService from "@/services/api.service";
import { File, tFile } from "@/models/file";

const api = new ApiService();

class FileApi {
  public async upload(params: FormData): Promise<File> {
    return await api.post("/files", params, { tModel: tFile });
  }
}

export default new FileApi();
