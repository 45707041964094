import i18n from "@/plugins/vue-i18n";

const AdminCampaigns = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/admin/campaigns/index");
const AdminCampaignEdit = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/admin/campaigns/edit");
const AdminCampaignCreatives = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/admin/campaigns/creatives/index");
const AdminCampaignCreativeAdd = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/admin/campaigns/creatives/add");

const campaigns = [
  {
    path: "campaigns",
    name: "AdminCampaigns",
    component: AdminCampaigns,
    meta: {
      title: i18n.t("title.campaigns"),
    },
  },
  {
    path: "campaigns/:id/edit",
    name: "AdminCampaignEdit",
    component: AdminCampaignEdit,
    meta: {
      title: i18n.t("title.campaign"),
    },
  },
  {
    path: "campaigns/:id/related-creatives",
    name: "AdminCampaignCreatives",
    component: AdminCampaignCreatives,
    meta: {
      title: i18n.t("title.creatives"),
    },
  },
  {
    path: "campaigns/:id/related-creatives/add",
    name: "AdminCampaignCreativeAdd",
    component: AdminCampaignCreativeAdd,
    meta: {
      title: i18n.t("title.creativeAdd"),
    },
  },
];

export default campaigns;
