import ApiService from "@/services/api.service";
import {
  IGetUsersQuery,
  Users,
  IDeleteUserQuery,
  IUserChangePasswordRequest,
  IUserChangePasswordByTokenParams,
  tUser,
  User,
  tUsers,
  tCheckPassword,
  CheckPassword,
  CheckPasswordParams,
} from "@/models/user";
import { AxiosResponse } from "axios";
import { ISettings } from "@/models/common";
import QS from "qs";

const api = new ApiService();

class UserApi {
  public async update(
    params: User,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tUser }
  ): Promise<User> {
    return await api.patch(`/users/${params.id}`, params, settings);
  }

  public async find(
    id: number,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tUser }
  ): Promise<User> {
    return await api.get(`/users/${id}`, settings);
  }

  public async create(
    params: User,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tUser }
  ): Promise<User> {
    return await api.post(`/users`, params, settings);
  }

  public async checkPassword(
    id: number,
    params: CheckPasswordParams,
    settings: ISettings = { withLoader: false, tModel: tCheckPassword }
  ): Promise<CheckPassword> {
    return await api.post(`/users/${id}/password/check`, params, settings);
  }

  public async changePassword(
    id: number,
    params: IUserChangePasswordRequest,
    settings: ISettings = { withAxiosResponseOnSuccess: true }
  ): Promise<AxiosResponse> {
    return await api.post(`/users/${id}/password/change`, params, settings);
  }

  public async changePasswordByToken(
    token: string,
    params: IUserChangePasswordByTokenParams,
    settings: ISettings = { withAxiosResponseOnSuccess: true }
  ): Promise<AxiosResponse> {
    return await api.post(`/users/password/restore/${token}`, params, settings);
  }

  public async confirmAccount(
    token: string,
    settings: ISettings = { withAxiosResponseOnSuccess: true }
  ): Promise<AxiosResponse> {
    return await api.post(`/users/account/confirm/${token}`, settings);
  }

  public async get(query: IGetUsersQuery = {}): Promise<Users> {
    const queryString = QS.stringify(query);

    return await api.get(`/users?${queryString}`, { tModel: tUsers });
  }

  public async delete(
    id: string,
    query: IDeleteUserQuery,
    settings: ISettings = { withAxiosResponseOnSuccess: true, resultKeyOnSuccess: "data" }
  ): Promise<AxiosResponse> {
    const queryString = QS.stringify(query);

    return await api.delete(`/users/${id}?${queryString}`, settings);
  }
}

export default new UserApi();
