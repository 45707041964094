import i18n from "@/plugins/vue-i18n";

const Campaigns = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/advert/campaigns/index");
const CampaignAdd = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/advert/campaigns/add");
const CampaignEdit = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/advert/campaigns/edit");
const CampaignCreatives = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/advert/campaigns/creatives/index");
const CampaignCreativeAdd = () =>
  import(/* webpackChunkName: "campaigns" */ "@/views/advert/campaigns/creatives/add");

const campaigns = [
  {
    path: "/campaigns",
    name: "Campaigns",
    component: Campaigns,
    meta: {
      title: i18n.t("title.campaigns"),
    },
  },
  {
    path: "/campaigns/add",
    name: "CampaignAdd",
    component: CampaignAdd,
    meta: {
      title: i18n.t("title.campaignAdd"),
    },
  },
  {
    path: "/campaigns/:id/edit",
    name: "CampaignEdit",
    component: CampaignEdit,
    meta: {
      title: i18n.t("title.campaign"),
    },
  },
  {
    path: "/campaigns/:id/related-creatives",
    name: "CampaignCreatives",
    component: CampaignCreatives,
    meta: {
      title: i18n.t("title.creatives"),
    },
  },
  {
    path: "/campaigns/:id/related-creatives/add",
    name: "CampaignCreativeAdd",
    component: CampaignCreativeAdd,
    meta: {
      title: i18n.t("title.creativeAdd"),
    },
  },
];

export default campaigns;
