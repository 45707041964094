import i18n from "@/plugins/vue-i18n";

const FiltersAdmin = () =>
  import(/* webpackChunkName: "filter-lists" */ "@/views/admin/filter-lists/index");
const FilterAddAdmin = () =>
  import(/* webpackChunkName: "filter-lists" */ "@/views/admin/filter-lists/add");
const FilterEditAdmin = () =>
  import(/* webpackChunkName: "filter-lists" */ "@/views/admin/filter-lists/edit");

const filters = [
  {
    path: "filter-lists",
    name: "FilterListsAdmin",
    component: FiltersAdmin,
    meta: {
      title: i18n.t("title.filterLists"),
    },
  },
  {
    path: "filter-lists/add",
    name: "FilterListAddAdmin",
    component: FilterAddAdmin,
    meta: {
      title: i18n.t("title.filterList"),
    },
  },
  {
    path: "filter-lists/:id",
    name: "FilterListEditAdmin",
    component: FilterEditAdmin,
    meta: {
      title: i18n.t("title.filterList"),
    },
  },
];

export default filters;
