import signUp from "./sign-up";
import login from "./login";
import forgotPassword from "./forgot-password";
import changePassword from "./change-password";
import confirmAccount from "./confirm-account";
import passwordSuccessfullyReset from "./password-successfully-reset";
import checkEmail from "./check-email";

const AuthLayout = () => import(/* webpackChunkName: "auth" */ "@/layouts/auth");

const authLayout = [
  {
    path: "/",
    name: "AuthLayout",
    redirect: { name: "Login" },
    component: AuthLayout,
    children: [
      ...signUp,
      ...login,
      ...forgotPassword,
      ...changePassword,
      ...confirmAccount,
      ...passwordSuccessfullyReset,
      ...checkEmail,
    ],
  },
];

export default authLayout;
