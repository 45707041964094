import i18n from "@/plugins/vue-i18n";

const AdminActivityLogs = () =>
  import(/* webpackChunkName: "adminReports" */ "@/views/admin/activity-logs");

const activityLogs = [
  {
    path: "activity-logs",
    name: "AdminActivityLogs",
    component: AdminActivityLogs,
    meta: {
      title: i18n.t("title.activityLogs"),
    },
  },
];

export default activityLogs;
