import MediaAssetApi from "@/api/media-asset";

export default {
  namespaced: true,

  actions: {
    async getImage(context, id) {
      return await MediaAssetApi.getImage(id);
    },

    async uploadImage(context, file) {
      const formData = new FormData();

      formData.append("media_assets[]", file);
      formData.append("image_video", "0");

      const [item] = await MediaAssetApi.uploadImage(formData);

      return item;
    },

    async uploadVideo(context, file) {
      const formData = new FormData();

      formData.append("media_assets[]", file);
      formData.append("image_video", "1");

      const [item] = await MediaAssetApi.uploadVideo(formData);

      return item;
    },
  },
};
