import email from "./email";
import password from "./password";
import passwordChanged from "./passwordChanged";

const ThanksLayout = () => import(/* webpackChunkName: "thanks" */ "@/layouts/thanks");

const thanksLayout = [
  {
    path: "/",
    name: "ThanksLayout",
    component: ThanksLayout,
    redirect: { name: "AdvertLayout" },
    children: [...email, ...password, ...passwordChanged],
  },
];

export default thanksLayout;
