import Vue from "vue";
import axios, { Axios, AxiosRequestHeaders, AxiosResponse } from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/services/jwt.service";
import store from "@/store";
import router from "@/router";
import { ISettings } from "@/models/common";
import i18n from "@/plugins/vue-i18n";

import { values, flatten, camelCase, get, isEqual } from "lodash";
import { filterDeep } from "deepdash-es/standalone";
import NotifyService from "@/services/notify.service";
import * as t from "io-ts";
import { isLeft } from "fp-ts/Either";
import { TypeC } from "io-ts";
import diff from "microdiff";

/**
 * Service to call HTTP request via Axios
 */
export default class ApiService {
  /**
   * Inits axios
   */
  public static init() {
    const restApiPrefix = process.env.VUE_APP_API_PREFIX;
    let baseUrl = process.env.VUE_APP_API_DOMAIN;

    const PROTOCOL_BACKSLASHES = "//";

    const [protocol]: Array<string> = baseUrl?.split(PROTOCOL_BACKSLASHES)!;
    const isFullBaseUrl = protocol === "https:" || protocol === "http:";

    baseUrl = baseUrl && isFullBaseUrl ? baseUrl : document.location.origin;

    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = baseUrl + restApiPrefix;
    this.setInterceptors(Vue.axios);
  }

  /**
   * Set api interceptors
   */
  private static setInterceptors($api: Axios) {
    $api.interceptors.response.use(
      (response) => {
        loaderOff(response.config.url!);

        return response;
      },
      (error) => {
        const status = error.response?.status;

        const routeName = router.currentRoute.value.name;
        const isLoginPage = routeName === "Login";

        loaderOff(error.config.url);

        if (status === 401 && !isLoginPage) {
          store.dispatch("auth/clearAuth");

          router.push({ name: "Unauthorized" });
        }

        if (status === 403) {
          if (error.response?.data?.message === "restricted_russia") {
            store.dispatch("auth/clearAuth");

            router.push({ name: "RussiaRestrictedError" });
          }
        }

        if (status === 404) {
          router.push({ name: "NotFound" });
        }

        if (status === 500) {
          router.push({ name: "InternalServerError" });
        }

        return Promise.reject(error);
      }
    );
  }

  /**
   * Set the default HTTP request headers
   */
  public static setHeaders() {
    const headers: AxiosRequestHeaders = {
      Authorization: `Bearer ${JwtService.getAuthToken()}`,
      Accept: "application/json",
    };

    const isAdmin = store.getters["user/isAdmin"];
    const isExistSudoProfile = store.getters["user/isExistSudoProfile"];

    if (isAdmin && isExistSudoProfile) {
      const { id } = store.getters["user/userProfile"];

      headers.SudoUser = id;
    }

    Vue.axios.defaults.headers.common = headers;
  }

  /**
   * Send the GET HTTP request
   */
  public get(resource: string, settings: ISettings = {}): Promise<AxiosResponse | any> {
    const {
      withLoader = true,
      withAxiosResponseOnSuccess = false,
      resultKeyOnSuccess = "",
      responseType = "json",
      tModel,
    } = settings;

    if (withLoader) loaderOn(resource);

    return Vue.axios.get(resource, { responseType }).then((response: any) => {
      if (resultKeyOnSuccess) response.data = get(response.data, resultKeyOnSuccess);

      const value = withAxiosResponseOnSuccess ? response : response.data;

      return tModel ? checkData(value, tModel) : value;
    });
  }

  /**
   * Set the POST HTTP request
   */
  public post(
    resource: string,
    params: any = null,
    settings: ISettings = {}
  ): Promise<AxiosResponse | any> {
    const {
      withLoader = true,
      withAxiosResponseOnSuccess = false,
      resultKeyOnSuccess = "",
      showErrors = false,
      responseType = "json",
      tModel,
    } = settings;

    if (withLoader) loaderOn(resource);

    return Vue.axios
      .post(resource, params, { responseType })
      .then((response: any) => {
        if (resultKeyOnSuccess) response.data = get(response.data, resultKeyOnSuccess);

        const value = withAxiosResponseOnSuccess ? response : response.data;

        return tModel ? checkData(value, tModel) : value;
      })
      .catch((error: any) => {
        if (error.response?.data) {
          const { data } = error.response;

          if (showErrors) handleErrors(data);
        }

        return Promise.reject(error);
      });
  }

  /**
   * Send the PUT HTTP request
   */
  public put(
    resource: string,
    params: any = null,
    settings: ISettings = {}
  ): Promise<AxiosResponse | any> {
    const {
      withLoader = true,
      withAxiosResponseOnSuccess = false,
      resultKeyOnSuccess = "",
      showErrors = false,
      tModel,
    } = settings;

    if (withLoader) loaderOn(resource);

    return Vue.axios
      .put(resource, params)
      .then((response: any) => {
        if (resultKeyOnSuccess) response.data = get(response.data, resultKeyOnSuccess);

        const value = withAxiosResponseOnSuccess ? response : response.data;

        return tModel ? checkData(value, tModel) : value;
      })
      .catch((error: any) => {
        if (error.response?.data) {
          const { data } = error.response;

          if (showErrors) handleErrors(data);
        }

        return Promise.reject(error);
      });
  }

  /**
   * Send the PATCH HTTP request
   */
  public patch(
    resource: string,
    params: any = null,
    settings: ISettings = {}
  ): Promise<AxiosResponse | any> {
    const {
      withLoader = true,
      withAxiosResponseOnSuccess = false,
      resultKeyOnSuccess = "",
      showErrors = false,
      tModel,
    } = settings;

    if (withLoader) loaderOn(resource);

    return Vue.axios
      .patch(resource, params)
      .then((response: any) => {
        if (resultKeyOnSuccess) response.data = get(response.data, resultKeyOnSuccess);

        const value = withAxiosResponseOnSuccess ? response : response.data;

        return tModel ? checkData(value, tModel) : value;
      })
      .catch((error: any) => {
        if (error.response?.data) {
          const { data } = error.response;

          if (showErrors) handleErrors(data);
        }

        return Promise.reject(error);
      });
  }

  /**
   * Send the DELETE HTTP request
   */
  public delete(resource: string, settings: ISettings = {}): Promise<AxiosResponse | any> {
    const {
      withLoader = true,
      withAxiosResponseOnSuccess = false,
      resultKeyOnSuccess = "",
      tModel,
    } = settings;

    if (withLoader) loaderOn(resource);

    return Vue.axios.delete(resource).then((response: any) => {
      if (resultKeyOnSuccess) response.data = get(response.data, resultKeyOnSuccess);

      const value = withAxiosResponseOnSuccess ? response : response.data;

      return tModel ? checkData(value, tModel) : value;
    });
  }
}

function loaderOn(apiEndpoint: string) {
  store.commit("loader/SET_LOADING_ON", apiEndpoint);
}

function loaderOff(apiEndpoint: string) {
  store.commit("loader/SET_LOADING_OFF", apiEndpoint);
}

function handleErrors(data: { errors: object; error: string }) {
  if ("errors" in data) {
    flatten(values(data.errors)).map((item: string) => {
      const code = camelCase(item);

      i18n.te(`component.notify.error.${code}`)
        ? NotifyService.error(code)
        : NotifyService.notify({
            type: "error",
            text: item,
            duration: NotifyService.DURATION_LONG,
          });
    });
  }

  if ("error" in data) {
    const code = camelCase(data.error);

    i18n.te(`component.notify.error.${code}`)
      ? NotifyService.error(code)
      : NotifyService.notify({
          type: "error",
          text: data.error,
          duration: NotifyService.DURATION_LONG,
        });
  }
}

export const checkData = (
  data: object,
  codec: t.ExactC<any> | t.ArrayC<any> | t.IntersectionC<any> | TypeC<any>
) => {
  if (process.env.VUE_APP_ENV === "local") {
    const result = codec.decode(data);

    if (isLeft(result)) {
      // eslint-disable-next-line no-console
      console.log(result.left);

      throw new ValidationError(["Fields failed value type checking."]);
    }

    const resultRight = filterDeep(result.right, (value) => value !== undefined);

    if (!isEqual(data, resultRight)) {
      // eslint-disable-next-line no-console
      console.log(diff(data, resultRight));

      throw new ValidationError(["Field mismatch."]);
    }

    return resultRight;
  } else {
    return data;
  }
};

class ValidationError extends Error {
  constructor(errors: string[]) {
    const errorMessage = errors.join(",\n");

    super(errorMessage);
  }
}
