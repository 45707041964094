import i18n from "@/plugins/vue-i18n";
import {
  languages,
  browsers,
  hours,
  days,
  distributions,
  pricingModels,
  statuses,
  macros,
  optimizationActions,
  mimeTypes,
  playerSizes,
} from "@/data/campaign";

import { trafficTypes, operationSystems } from "@/data/common";
import { camelCase } from "lodash";

export default {
  namespaced: true,

  state: {
    trafficTypes,
    languages,
    browsers,
    operationSystems,
    distributions,
    hours,
    days,
    pricingModels,
    statuses,
    macros,
    optimizationActions,
    mimeTypes: mimeTypes,
    playerSizes,

    statusColors: {
      pending: "jaffa-2",
      approved: "jungle-green",
      disapproved: "burnt-sienna",
      disabled: "mystic",
    },

    geoCountries: [],
    geoRegions: [],
    geoCities: [],

    weekends: ["saturday", "sunday"],
  },

  getters: {
    pricingModelOptions(state) {
      return state.pricingModels.map((code) => ({
        code,
        label: i18n.t(`campaign.pricingModel.${code}`),
      }));
    },

    languageOptions(state) {
      return state.languages.map((code) => ({
        code,
        label: i18n.t(`campaign.language.${code}`),
      }));
    },

    browserOptions(state) {
      return state.browsers.map((code) => ({
        code,
        label: i18n.t(`campaign.browser.${camelCase(code)}`),
      }));
    },

    distributionOptions(state) {
      return state.distributions.map((code) => ({
        code,
        label: i18n.t(`campaign.distribution.${code}`),
      }));
    },

    hourList(state) {
      return state.hours.map((code) => ({
        code,
        label: i18n.t(`campaign.hour.${code}`),
      }));
    },

    dayList(state) {
      return state.days.map((code) => ({
        code,
        label: i18n.t(`campaign.day.${code}`),
      }));
    },

    statusOptions(state) {
      return state.statuses.map((code) => ({
        code,
        label: i18n.t(`campaign.status.${camelCase(code)}`),
      }));
    },

    macrosOptions(state) {
      return state.macros.map((code) => ({
        code: `[${code}]`,
        description: i18n.t(`campaign.macros.${camelCase(code)}`),
      }));
    },

    optimizationActionOptions(state) {
      return state.optimizationActions.map((code) => ({
        code,
        label: i18n.t(`campaign.optimizationActions.${code}`),
      }));
    },

    mimeTypeOptions(state) {
      return state.mimeTypes.map((code) => ({
        code,
        label: i18n.t(`campaign.mimeTypes.${code}`),
      }));
    },

    playerSizeOptions(state) {
      return state.playerSizes.map((code) => ({
        code,
        label: i18n.t(`campaign.playerSizes.${code}`),
      }));
    },

    statusColorsList(state) {
      return state.statusColors;
    },
  },
};
