import store from "@/store";

export default async (to, from, next) => {
  const toMatchedNames = to.matched.map(({ name }) => name);

  const isAuthenticatedUser = store.getters["auth/isAuthenticatedUser"];

  const isAuthPage = toMatchedNames.includes("AuthLayout");
  const isAdvertPage = toMatchedNames.includes("AdvertLayout");
  const isAdminPage = toMatchedNames.includes("AdminLayout");
  const isManagerPage = toMatchedNames.includes("ManagerLayout");

  if (!isAuthPage && isAuthenticatedUser) {
    await store.dispatch("auth/verifyAuth");

    const isExistProfile = store.getters["user/isExistProfile"];

    if (!isExistProfile) {
      await store.dispatch("user/getProfile");
    }
  }

  const isAdvert = store.getters["user/isAdvert"];
  const isAdmin = store.getters["user/isAdmin"];
  const isManager = store.getters["user/isManager"];

  if (isAuthPage) {
    if (isAuthenticatedUser) next({ name: "AdvertLayout" });
  }

  if (isAdvertPage) {
    if (!isAuthenticatedUser) next({ name: "Login" });

    if (isManager) next({ name: "ManagerLayout" });
  }

  if (isAdminPage) {
    if (!isAdmin) next({ name: "NotFound" });
  }

  if (isManagerPage) {
    if (isAdvert || !isAuthenticatedUser) next({ name: "NotFound" });
  }

  next();
};
