export const statuses = ["pending", "approved", "disapproved"];

export const logoImageSizes = [
  {
    width: 256,
    height: 256,
  },
  {
    width: 120,
    height: 120,
  },
];

export const bannerImageSizes = [
  {
    width: 160,
    height: 600,
  },
  {
    width: 300,
    height: 100,
  },
  {
    width: 300,
    height: 250,
  },
  {
    width: 320,
    height: 250,
  },
  {
    width: 320,
    height: 50,
  },
  {
    width: 320,
    height: 100,
  },
  {
    width: 320,
    height: 480,
  },
  {
    width: 468,
    height: 60,
  },
  {
    width: 720,
    height: 90,
  },

  {
    width: 728,
    height: 90,
  },
  {
    width: 900,
    height: 250,
  },
];

export const nativeImageSizes = [
  {
    width: 492,
    height: 328,
  },
];

export const videoSizes = [
  {
    width: 1920,
    height: 1080,
  },
  {
    width: 300,
    height: 250,
  },
  {
    width: 728,
    height: 90,
  },
  {
    width: 160,
    height: 600,
  },
  {
    width: 240,
    height: 400,
  },
  {
    width: 300,
    height: 600,
  },
  {
    width: 468,
    height: 60,
  },
  {
    width: 320,
    height: 50,
  },
  {
    width: 300,
    height: 50,
  },
  {
    width: 970,
    height: 250,
  },
  {
    width: 320,
    height: 480,
  },
  {
    width: 250,
    height: 250,
  },
];

export const allSizes = [
  {
    width: 2350,
    height: 1197,
  },
  {
    width: 2106,
    height: 1080,
  },
  {
    width: 1920,
    height: 1080,
  },
  {
    width: 1920,
    height: 837,
  },
  {
    width: 1802,
    height: 810,
  },
  {
    width: 1788,
    height: 810,
  },
  {
    width: 1775,
    height: 885,
  },
  {
    width: 1692,
    height: 1020,
  },
  {
    width: 1555,
    height: 720,
  },
  {
    width: 1554,
    height: 720,
  },
  {
    width: 1536,
    height: 722,
  },
  {
    width: 1520,
    height: 124,
  },
  {
    width: 1470,
    height: 150,
  },
  {
    width: 1456,
    height: 180,
  },
  {
    width: 1440,
    height: 2988,
  },
  {
    width: 1440,
    height: 2831,
  },
  {
    width: 1440,
    height: 2820,
  },
  {
    width: 1440,
    height: 2733,
  },
  {
    width: 1440,
    height: 2712,
  },
  {
    width: 1393,
    height: 750,
  },
  {
    width: 1280,
    height: 800,
  },
  {
    width: 1280,
    height: 720,
  },
  {
    width: 1272,
    height: 657,
  },
  {
    width: 1220,
    height: 400,
  },
  {
    width: 1210,
    height: 605,
  },
  {
    width: 1210,
    height: 150,
  },
  {
    width: 1136,
    height: 640,
  },
  {
    width: 1096,
    height: 2434,
  },
  {
    width: 1080,
    height: 2420,
  },
  {
    width: 1080,
    height: 2402,
  },
  {
    width: 1080,
    height: 2394,
  },
  {
    width: 1080,
    height: 2313,
  },
  {
    width: 1080,
    height: 2215,
  },
  {
    width: 1080,
    height: 2194,
  },
  {
    width: 1080,
    height: 2187,
  },
  {
    width: 1080,
    height: 2182,
  },
  {
    width: 1080,
    height: 2173,
  },
  {
    width: 1080,
    height: 2166,
  },
  {
    width: 1080,
    height: 2164,
  },
  {
    width: 1080,
    height: 2150,
  },
  {
    width: 1080,
    height: 2147,
  },
  {
    width: 1080,
    height: 2124,
  },
  {
    width: 1080,
    height: 2122,
  },
  {
    width: 1080,
    height: 2115,
  },
  {
    width: 1080,
    height: 2114,
  },
  {
    width: 1080,
    height: 2106,
  },
  {
    width: 1080,
    height: 2055,
  },
  {
    width: 1080,
    height: 2050,
  },
  {
    width: 1080,
    height: 2046,
  },
  {
    width: 1080,
    height: 168,
  },
  {
    width: 1080,
    height: 150,
  },
  {
    width: 1070,
    height: 609,
  },
  {
    width: 1070,
    height: 487,
  },
  {
    width: 1024,
    height: 768,
  },
  {
    width: 1024,
    height: 90,
  },
  {
    width: 1020,
    height: 200,
  },
  {
    width: 1015,
    height: 2067,
  },
  {
    width: 1000,
    height: 90,
  },
  {
    width: 1000,
    height: 30,
  },
  {
    width: 980,
    height: 1960,
  },
  {
    width: 980,
    height: 1882,
  },
  {
    width: 980,
    height: 1872,
  },
  {
    width: 980,
    height: 300,
  },
  {
    width: 980,
    height: 90,
  },
  {
    width: 970,
    height: 631,
  },
  {
    width: 970,
    height: 250,
  },
  {
    width: 970,
    height: 100,
  },
  {
    width: 970,
    height: 91,
  },
  {
    width: 970,
    height: 90,
  },
  {
    width: 960,
    height: 640,
  },
  {
    width: 960,
    height: 480,
  },
  {
    width: 960,
    height: 150,
  },
  {
    width: 950,
    height: 90,
  },
  {
    width: 944,
    height: 844,
  },
  {
    width: 943,
    height: 475,
  },
  {
    width: 923,
    height: 519,
  },
  {
    width: 918,
    height: 1765,
  },
  {
    width: 905,
    height: 1539,
  },
  {
    width: 900,
    height: 250,
  },
  {
    width: 900,
    height: 90,
  },
  {
    width: 879,
    height: 406,
  },
  {
    width: 849,
    height: 477,
  },
  {
    width: 841,
    height: 1393,
  },
  {
    width: 838,
    height: 1669,
  },
  {
    width: 838,
    height: 1445,
  },
  {
    width: 837,
    height: 1431,
  },
  {
    width: 833,
    height: 1592,
  },
  {
    width: 828,
    height: 382,
  },
  {
    width: 820,
    height: 1582,
  },
  {
    width: 820,
    height: 1180,
  },
  {
    width: 810,
    height: 1802,
  },
  {
    width: 810,
    height: 1546,
  },
  {
    width: 810,
    height: 126,
  },
  {
    width: 810,
    height: 110,
  },
  {
    width: 808,
    height: 454,
  },
  {
    width: 805,
    height: 1640,
  },
  {
    width: 800,
    height: 1280,
  },
  {
    width: 800,
    height: 600,
  },
  {
    width: 800,
    height: 250,
  },
  {
    width: 785,
    height: 1672,
  },
  {
    width: 785,
    height: 1631,
  },
  {
    width: 785,
    height: 150,
  },
  {
    width: 780,
    height: 1432,
  },
  {
    width: 770,
    height: 1587,
  },
  {
    width: 770,
    height: 1223,
  },
  {
    width: 768,
    height: 1024,
  },
  {
    width: 768,
    height: 384,
  },
  {
    width: 768,
    height: 150,
  },
  {
    width: 766,
    height: 383,
  },
  {
    width: 766,
    height: 150,
  },
  {
    width: 754,
    height: 150,
  },
  {
    width: 753,
    height: 1674,
  },
  {
    width: 753,
    height: 1669,
  },
  {
    width: 753,
    height: 410,
  },
  {
    width: 750,
    height: 300,
  },
  {
    width: 750,
    height: 200,
  },
  {
    width: 750,
    height: 100,
  },
  {
    width: 749,
    height: 421,
  },
  {
    width: 742,
    height: 384,
  },
  {
    width: 740,
    height: 1667,
  },
  {
    width: 736,
    height: 414,
  },
  {
    width: 730,
    height: 411,
  },
  {
    width: 728,
    height: 250,
  },
  {
    width: 728,
    height: 90,
  },
  {
    width: 720,
    height: 1457,
  },
  {
    width: 720,
    height: 1430,
  },
  {
    width: 720,
    height: 1428,
  },
  {
    width: 720,
    height: 1378,
  },
  {
    width: 720,
    height: 1366,
  },
  {
    width: 720,
    height: 1280,
  },
  {
    width: 720,
    height: 360,
  },
  {
    width: 712,
    height: 1669,
  },
  {
    width: 705,
    height: 150,
  },
  {
    width: 702,
    height: 1576,
  },
  {
    width: 689,
    height: 150,
  },
  {
    width: 687,
    height: 150,
  },
  {
    width: 680,
    height: 150,
  },
  {
    width: 658,
    height: 1540,
  },
  {
    width: 650,
    height: 110,
  },
  {
    width: 650,
    height: 90,
  },
  {
    width: 640,
    height: 1136,
  },
  {
    width: 640,
    height: 960,
  },
  {
    width: 640,
    height: 480,
  },
  {
    width: 640,
    height: 360,
  },
  {
    width: 640,
    height: 300,
  },
  {
    width: 640,
    height: 100,
  },
  {
    width: 638,
    height: 1669,
  },
  {
    width: 624,
    height: 312,
  },
  {
    width: 624,
    height: 150,
  },
  {
    width: 620,
    height: 366,
  },
  {
    width: 600,
    height: 500,
  },
  {
    width: 600,
    height: 300,
  },
  {
    width: 600,
    height: 200,
  },
  {
    width: 591,
    height: 641,
  },
  {
    width: 580,
    height: 400,
  },
  {
    width: 533,
    height: 816,
  },
  {
    width: 480,
    height: 320,
  },
  {
    width: 468,
    height: 60,
  },
  {
    width: 438,
    height: 197,
  },
  {
    width: 432,
    height: 786,
  },
  {
    width: 428,
    height: 846,
  },
  {
    width: 428,
    height: 745,
  },
  {
    width: 424,
    height: 842,
  },
  {
    width: 424,
    height: 829,
  },
  {
    width: 424,
    height: 705,
  },
  {
    width: 420,
    height: 866,
  },
  {
    width: 420,
    height: 858,
  },
  {
    width: 420,
    height: 834,
  },
  {
    width: 418,
    height: 951,
  },
  {
    width: 418,
    height: 927,
  },
  {
    width: 414,
    height: 816,
  },
  {
    width: 414,
    height: 796,
  },
  {
    width: 414,
    height: 719,
  },
  {
    width: 414,
    height: 715,
  },
  {
    width: 414,
    height: 260,
  },
  {
    width: 414,
    height: 104,
  },
  {
    width: 412,
    height: 912,
  },
  {
    width: 412,
    height: 837,
  },
  {
    width: 412,
    height: 836,
  },
  {
    width: 412,
    height: 824,
  },
  {
    width: 412,
    height: 814,
  },
  {
    width: 412,
    height: 806,
  },
  {
    width: 412,
    height: 206,
  },
  {
    width: 411,
    height: 936,
  },
  {
    width: 411,
    height: 912,
  },
  {
    width: 411,
    height: 849,
  },
  {
    width: 411,
    height: 845,
  },
  {
    width: 411,
    height: 842,
  },
  {
    width: 411,
    height: 838,
  },
  {
    width: 411,
    height: 834,
  },
  {
    width: 411,
    height: 833,
  },
  {
    width: 411,
    height: 832,
  },
  {
    width: 411,
    height: 829,
  },
  {
    width: 411,
    height: 811,
  },
  {
    width: 411,
    height: 798,
  },
  {
    width: 411,
    height: 788,
  },
  {
    width: 411,
    height: 787,
  },
  {
    width: 411,
    height: 786,
  },
  {
    width: 411,
    height: 780,
  },
  {
    width: 411,
    height: 778,
  },
  {
    width: 411,
    height: 775,
  },
  {
    width: 411,
    height: 773,
  },
  {
    width: 411,
    height: 731,
  },
  {
    width: 411,
    height: 683,
  },
  {
    width: 411,
    height: 64,
  },
  {
    width: 410,
    height: 783,
  },
  {
    width: 410,
    height: 698,
  },
  {
    width: 404,
    height: 854,
  },
  {
    width: 404,
    height: 797,
  },
  {
    width: 404,
    height: 739,
  },
  {
    width: 400,
    height: 300,
  },
  {
    width: 393,
    height: 836,
  },
  {
    width: 393,
    height: 834,
  },
  {
    width: 393,
    height: 812,
  },
  {
    width: 393,
    height: 794,
  },
  {
    width: 393,
    height: 793,
  },
  {
    width: 393,
    height: 779,
  },
  {
    width: 393,
    height: 777,
  },
  {
    width: 393,
    height: 759,
  },
  {
    width: 393,
    height: 755,
  },
  {
    width: 393,
    height: 741,
  },
  {
    width: 392,
    height: 221,
  },
  {
    width: 391,
    height: 150,
  },
  {
    width: 390,
    height: 764,
  },
  {
    width: 390,
    height: 745,
  },
  {
    width: 390,
    height: 664,
  },
  {
    width: 390,
    height: 84,
  },
  {
    width: 390,
    height: 61,
  },
  {
    width: 389,
    height: 150,
  },
  {
    width: 384,
    height: 848,
  },
  {
    width: 384,
    height: 783,
  },
  {
    width: 384,
    height: 774,
  },
  {
    width: 384,
    height: 773,
  },
  {
    width: 384,
    height: 758,
  },
  {
    width: 384,
    height: 756,
  },
  {
    width: 384,
    height: 749,
  },
  {
    width: 384,
    height: 725,
  },
  {
    width: 384,
    height: 721,
  },
  {
    width: 382,
    height: 191,
  },
  {
    width: 380,
    height: 751,
  },
  {
    width: 380,
    height: 685,
  },
  {
    width: 376,
    height: 833,
  },
  {
    width: 376,
    height: 188,
  },
  {
    width: 376,
    height: 150,
  },
  {
    width: 375,
    height: 734,
  },
  {
    width: 375,
    height: 59,
  },
  {
    width: 375,
    height: 50,
  },
  {
    width: 360,
    height: 759,
  },
  {
    width: 360,
    height: 740,
  },
  {
    width: 360,
    height: 728,
  },
  {
    width: 360,
    height: 726,
  },
  {
    width: 360,
    height: 725,
  },
  {
    width: 360,
    height: 724,
  },
  {
    width: 360,
    height: 723,
  },
  {
    width: 360,
    height: 720,
  },
  {
    width: 360,
    height: 719,
  },
  {
    width: 360,
    height: 711,
  },
  {
    width: 360,
    height: 708,
  },
  {
    width: 360,
    height: 706,
  },
  {
    width: 360,
    height: 705,
  },
  {
    width: 360,
    height: 703,
  },
  {
    width: 360,
    height: 702,
  },
  {
    width: 360,
    height: 697,
  },
  {
    width: 360,
    height: 692,
  },
  {
    width: 360,
    height: 690,
  },
  {
    width: 360,
    height: 683,
  },
  {
    width: 360,
    height: 679,
  },
  {
    width: 360,
    height: 677,
  },
  {
    width: 360,
    height: 672,
  },
  {
    width: 360,
    height: 670,
  },
  {
    width: 360,
    height: 640,
  },
  {
    width: 360,
    height: 592,
  },
  {
    width: 360,
    height: 480,
  },
  {
    width: 360,
    height: 100,
  },
  {
    width: 355,
    height: 150,
  },
  {
    width: 353,
    height: 724,
  },
  {
    width: 352,
    height: 176,
  },
  {
    width: 352,
    height: 150,
  },
  {
    width: 350,
    height: 196,
  },
  {
    width: 348,
    height: 773,
  },
  {
    width: 346,
    height: 758,
  },
  {
    width: 338,
    height: 169,
  },
  {
    width: 338,
    height: 150,
  },
  {
    width: 336,
    height: 600,
  },
  {
    width: 336,
    height: 280,
  },
  {
    width: 330,
    height: 280,
  },
  {
    width: 325,
    height: 508,
  },
  {
    width: 322,
    height: 161,
  },
  {
    width: 322,
    height: 150,
  },
  {
    width: 320,
    height: 723,
  },
  {
    width: 320,
    height: 699,
  },
  {
    width: 320,
    height: 602,
  },
  {
    width: 320,
    height: 595,
  },
  {
    width: 320,
    height: 592,
  },
  {
    width: 320,
    height: 569,
  },
  {
    width: 320,
    height: 480,
  },
  {
    width: 320,
    height: 416,
  },
  {
    width: 320,
    height: 320,
  },
  {
    width: 320,
    height: 200,
  },
  {
    width: 320,
    height: 180,
  },
  {
    width: 320,
    height: 160,
  },
  {
    width: 320,
    height: 150,
  },
  {
    width: 320,
    height: 142,
  },
  {
    width: 320,
    height: 100,
  },
  {
    width: 320,
    height: 60,
  },
  {
    width: 320,
    height: 50,
  },
  {
    width: 304,
    height: 250,
  },
  {
    width: 303,
    height: 250,
  },
  {
    width: 302,
    height: 170,
  },
  {
    width: 301,
    height: 250,
  },
  {
    width: 300,
    height: 600,
  },
  {
    width: 300,
    height: 500,
  },
  {
    width: 300,
    height: 300,
  },
  {
    width: 300,
    height: 250,
  },
  {
    width: 300,
    height: 168,
  },
  {
    width: 300,
    height: 150,
  },
  {
    width: 300,
    height: 125,
  },
  {
    width: 300,
    height: 100,
  },
  {
    width: 300,
    height: 50,
  },
  {
    width: 290,
    height: 50,
  },
  {
    width: 280,
    height: 420,
  },
  {
    width: 260,
    height: 600,
  },
  {
    width: 257,
    height: 145,
  },
  {
    width: 250,
    height: 300,
  },
  {
    width: 250,
    height: 250,
  },
  {
    width: 244,
    height: 250,
  },
  {
    width: 240,
    height: 400,
  },
  {
    width: 234,
    height: 60,
  },
  {
    width: 213,
    height: 120,
  },
  {
    width: 200,
    height: 300,
  },
  {
    width: 200,
    height: 200,
  },
  {
    width: 160,
    height: 601,
  },
  {
    width: 160,
    height: 600,
  },
  {
    width: 125,
    height: 250,
  },
  {
    width: 120,
    height: 600,
  },
  {
    width: 120,
    height: 400,
  },
  {
    width: 120,
    height: 30,
  },
  {
    width: 75,
    height: 150,
  },
  {
    width: 10,
    height: 10,
  },
  {
    width: 5,
    height: 5,
  },
  {
    width: 2,
    height: 1,
  },
  {
    width: 1,
    height: 1,
  },
];
