import dashboard from "./dashboard";
import reports from "./reports";
import creatives from "./creatives";
import userManagement from "./user-management";
import activityLogs from "./activity-logs";
import paymnets from "./paymnets";
import campaigns from "./campaigns";
import promoCodes from "./promo-codes";
import filters from "./filters";
import globalBlacklist from "./global-blacklist";
const AdminLayout = () => import(/* webpackChunkName: "admin" */ "@/layouts/admin");

const adminLayout = [
  {
    path: "/admin",
    name: "AdminLayout",
    component: AdminLayout,
    redirect: { name: "AdminDashboard" },
    children: [
      ...dashboard,
      ...reports,
      ...creatives,
      ...userManagement,
      ...activityLogs,
      ...paymnets,
      ...campaigns,
      ...promoCodes,
      ...filters,
      ...globalBlacklist,
    ],
  },
];

export default adminLayout;
