import i18n from "@/plugins/vue-i18n";

const Filters = () =>
  import(/* webpackChunkName: "filter-lists" */ "@/views/advert/filter-lists/index");
const FilterAdd = () =>
  import(/* webpackChunkName: "filter-lists" */ "@/views/advert/filter-lists/add");
const FilterEdit = () =>
  import(/* webpackChunkName: "filter-lists" */ "@/views/advert/filter-lists/edit");

const faq = [
  {
    path: "/filter-lists",
    name: "FilterLists",
    component: Filters,
    meta: {
      title: i18n.t("title.filterLists"),
    },
  },
  {
    path: "/filter-lists/add",
    name: "FilterListAdd",
    component: FilterAdd,
    meta: {
      title: i18n.t("title.filterList"),
    },
  },
  {
    path: "/filter-lists/:id",
    name: "FilterListEdit",
    component: FilterEdit,
    meta: {
      title: i18n.t("title.filterList"),
    },
  },
];

export default faq;
