import { AxiosResponse } from "axios";
import ApiService from "@/services/api.service";
import {
  ICampaignCreativesParams,
  ICheckTargetUrl,
  tCreative,
  Creative,
  Creatives,
  tCreatives,
  CreativeCreateForm,
  CreativeUpdateForm,
  ICheckIframe,
} from "@/models/creative";
import { ISettings, ICheckTargetUrlResponse, ICheckIframeResponse } from "@/models/common";
import QS from "qs";
import { ListQuery } from "@/services/query-builder";

const api = new ApiService();

class CreativeAdvertApi {
  public async find(
    id: number,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tCreative }
  ): Promise<Creative> {
    return await api.get(`/creatives/${id}`, settings);
  }

  public async create(
    params: CreativeCreateForm,
    settings: ISettings = { showErrors: true, resultKeyOnSuccess: "data", tModel: tCreative }
  ): Promise<Creative> {
    return await api.post("/creatives", params, settings);
  }

  public async update(
    id: string | number,
    params: CreativeUpdateForm,
    settings: ISettings = { showErrors: true, resultKeyOnSuccess: "data" }
  ): Promise<Creative> {
    settings.tModel = tCreative;

    return await api.put(`/creatives/${id}`, params, settings);
  }

  public async delete(
    id: number | string,
    settings: ISettings = { withLoader: false, withAxiosResponseOnSuccess: true }
  ): Promise<AxiosResponse> {
    return await api.delete(`/creatives/${id}`, settings);
  }

  public async get(query: ListQuery): Promise<Creatives> {
    const queryString = QS.stringify(query);

    return await api.get(`/creatives?${queryString}`, { tModel: tCreatives });
  }

  public async duplicate(id: number): Promise<ICampaignCreativesParams> {
    return await api.post(`/creatives/${id}/duplicate`);
  }

  public async checkClickUrl(params: ICheckTargetUrl): Promise<ICheckTargetUrlResponse> {
    return await api.post("/creatives/check-click-url", params, { withLoader: false });
  }

  public async checkAdmBody(params: ICheckIframe): Promise<ICheckIframeResponse> {
    return await api.post("/creatives/check-adm-body", params, { withLoader: false });
  }
}

export default new CreativeAdvertApi();
