import Vue from "vue";

import { PerfectScrollbar } from "vue3-perfect-scrollbar";
PerfectScrollbar.compatConfig = {
  MODE: 3,
  INSTANCE_CHILDREN: true,
};
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";

Vue.component("PerfectScrollbar", PerfectScrollbar);
