import i18n from "@/plugins/vue-i18n";

const Settings = () => import(/* webpackChunkName: "settings" */ "@/views/advert/settings/index");

const settings = [
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      title: i18n.t("title.settings"),
    },
  },
];

export default settings;
