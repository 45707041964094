import { createRouter, createWebHistory } from "vue-router";
import advertLayout from "@/router/layouts/advertLayout";
import adminLayout from "@/router/layouts/adminLayout";
import authLayout from "@/router/layouts/authLayout";
import errorLayout from "@/router/layouts/errorLayout";
import thanksLayout from "@/router/layouts/thanksLayout";
import managerLayout from "@/router/layouts/managerLayout";
import onErrorMiddleware from "@/router/onErrorMiddleware";
import beforeEachMiddleware from "@/router/beforeEachMiddleware";
import afterEachMiddleware from "@/router/afterEachMiddleware";

const router = createRouter({
  history: createWebHistory(),
  base: process.env.VUE_APP_BASE_PATH,
  routes: [
    ...advertLayout,
    ...adminLayout,
    ...authLayout,
    ...errorLayout,
    ...thanksLayout,
    ...managerLayout,
    {
      path: "/:pathMatch(.*)*",
      redirect: "/404",
    },
  ],
});

router.onError(onErrorMiddleware);
router.beforeEach(beforeEachMiddleware);
router.afterEach(afterEachMiddleware);

export default router;
