import i18n from "@/plugins/vue-i18n";

const AdminUserManagement = () =>
  import(/* webpackChunkName: "adminUserManagement" */ "@/views/admin/user-management/index");

const AdminUserManagementEdit = () =>
  import(/* webpackChunkName: "adminUserManagement" */ "@/views/admin/user-management/edit");

const AdminUserManagementAdd = () =>
  import(/* webpackChunkName: "adminUserManagement" */ "@/views/admin/user-management/add");

const userManagement = [
  {
    path: "user-management",
    name: "AdminUserManagement",
    component: AdminUserManagement,
    meta: {
      title: i18n.t("title.userManagement"),
    },
  },
  {
    path: "user-management/:id/edit",
    name: "AdminUserManagementEdit",
    component: AdminUserManagementEdit,
    meta: {
      title: i18n.t("title.userProfile"),
    },
  },
  {
    path: "user-management/add",
    name: "AdminUserManagementAdd",
    component: AdminUserManagementAdd,
    meta: {
      title: i18n.t("title.userProfile"),
    },
  },
];

export default userManagement;
