import i18n from "@/plugins/vue-i18n";

const AdminReports = () => import(/* webpackChunkName: "adminReports" */ "@/views/admin/reports");

const reports = [
  {
    path: "reports",
    name: "AdminReports",
    component: AdminReports,
    meta: {
      title: i18n.t("title.reports"),
    },
  },
  {
    path: "reports/:id/",
    name: "AdminReportsEdit", //TODO: add report page for id
    component: AdminReports,
    meta: {
      title: i18n.t("title.reports"),
    },
  },
];

export default reports;
