import i18n from "@/plugins/vue-i18n";

const Unauthorized = () => import(/* webpackChunkName: "error" */ "@/views/error/Unauthorized");

const unauthorized = [
  {
    path: "/401",
    name: "Unauthorized",
    component: Unauthorized,
    meta: {
      title: i18n.t("title.unauthorized"),
    },
  },
];

export default unauthorized;
