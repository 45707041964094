import { types } from "@/data/filter-list";
import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,

  state: {
    types,
  },

  getters: {
    typeOptions(state) {
      return state.types.map((code) => ({
        code,
        label: i18n.t(`filterList.type.${code}`),
      }));
    },
  },
};
