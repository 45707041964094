import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";
import i18n from "@/plugins/vue-i18n";
import router from "@/router";
import AuthApi from "@/api/auth";
import store from "@/store";
import { addMinutes, getUnixTime, fromUnixTime, differenceInMinutes } from "date-fns";

const AUTH_MAX_LIFETIME_PERCENTAGE = 100;
const AUTH_LOW_LIFETIME_PERCENTAGE = 25;

export default {
  namespaced: true,

  state: {
    token: JwtService.getAuthToken(),
    expires: JwtService.getAuthTokenExpiresTime(),
    jwtTTL: 60,
  },

  getters: {
    isAuthenticatedUser(state) {
      return !!state.token;
    },

    tokenValidityTime(state) {
      return Number(state.expires);
    },

    isTokenRefreshNeeded(state, getters) {
      const { jwtTTL } = state;
      const { tokenValidityTime } = getters;

      const authExpiresInMinutes = differenceInMinutes(fromUnixTime(tokenValidityTime), Date.now());
      const authLifetimePercentage = Math.floor(
        (authExpiresInMinutes / jwtTTL) * AUTH_MAX_LIFETIME_PERCENTAGE
      );

      return authLifetimePercentage <= AUTH_LOW_LIFETIME_PERCENTAGE;
    },

    copyright() {
      const year = new Date().getFullYear();
      const appName = process.env.VUE_APP_NAME;

      return i18n.t("label.copyright", { year, appName });
    },
  },

  mutations: {
    SET_AUTH(state, { access_token, expires_in }) {
      const now = Date.now();

      state.token = access_token;
      state.expires = getUnixTime(addMinutes(now, expires_in));
      state.jwtTTL = expires_in;

      JwtService.saveAuthToken(state.token, state.expires);
      ApiService.setHeaders();
    },

    PURGE_AUTH(state) {
      state.token = null;
      state.expires = null;
    },
  },

  actions: {
    async login(context, params) {
      const response = await AuthApi.login(params);

      context.commit("SET_AUTH", response);

      await router.push({ name: "AdvertLayout" });
    },

    async signUp(context, params) {
      const query = {
        fromUserPage: true, // disable recaptcha (RND-137)
      };

      return await AuthApi.signUp(query, params).then(() => {
        router.push({ name: "CheckEmail" });
      });
    },

    async verifyAuth(context) {
      const { isAuthenticatedUser, isTokenRefreshNeeded } = context.getters;

      if (isAuthenticatedUser) {
        ApiService.setHeaders();

        if (isTokenRefreshNeeded) {
          await context.dispatch("refreshAuth");
        }
      } else {
        await context.dispatch("clearAuth");
      }
    },

    async refreshAuth(context) {
      const response = await AuthApi.refresh();

      context.commit("SET_AUTH", response);
    },

    async logout(context, params) {
      await AuthApi.logout(params);

      await context.dispatch("clearAuth");

      await router.push({ name: "Login" }).catch(() => ({}));
    },

    async clearAuth(context) {
      context.commit("PURGE_AUTH");

      store.commit("user/SET_PROFILE");
      store.commit("user/SET_SUDO_PROFILE");

      JwtService.destroyAuthToken();
    },
  },
};
