import i18n from "@/plugins/vue-i18n";
import {
  statuses,
  bannerImageSizes,
  nativeImageSizes,
  logoImageSizes,
  allSizes,
  videoSizes,
} from "@/data/creative";
import CreativeAdminApi from "@/api/admin/creatives";
import CreativeAdvertApi from "@/api/advert/creatives";
import { pick } from "lodash";
import NotifyService from "@/services/notify.service";

export default {
  namespaced: true,

  state: {
    statuses,
    logoImageSizes,
    bannerImageSizes,
    nativeImageSizes,
    allSizes,
    videoSizes,

    statusColors: {
      pending: "jaffa-2",
      approved: "jungle-green",
      disapproved: "burnt-sienna",
      disabled: "mystic",
    },

    pendingCreatives: {
      data: [],
      pagination: {},
      sort: {
        column: "id",
        direction: "desc",
      },
    },
  },

  getters: {
    logoImageSizeOptions(state) {
      return state.logoImageSizes.map(({ width, height }) => ({
        value: { width, height },
        label: `${width}x${height}`,
      }));
    },

    bannerImageSizeOptions(state) {
      return state.bannerImageSizes.map(({ width, height }) => ({
        value: { width, height },
        label: `${width}x${height}`,
      }));
    },

    nativeImageSizeOptions(state) {
      return state.nativeImageSizes.map(({ width, height }) => ({
        value: { width, height },
        label: `${width}x${height}`,
      }));
    },

    videoSizeOptions(state) {
      return state.videoSizes.map(({ width, height }) => ({
        value: { width, height },
        label: `${width}x${height}`,
      }));
    },

    allSizesOptions(state) {
      return state.allSizes.map(({ width, height }) => ({
        value: { width, height },
        label: `${width}x${height}`,
      }));
    },

    statusOptions(state) {
      return state.statuses.map((code) => ({
        code,
        label: i18n.t(`creative.status.${code}`),
      }));
    },

    pendingCreatives(state) {
      return {
        tableRows: state.pendingCreatives.data.map((item) => {
          let { approved: status, isActive, id, name, user = {}, media } = item;

          if (!isActive) status = "disabled";
          const statusColor = state.statusColors[status];
          const preview = pick(media, ["url", "originName"]);
          const { firstName, lastName } = user;
          const fullName = `${firstName} ${lastName}`.trim();
          const userId = user.id;

          return {
            id,
            preview,
            name,
            advertiser: fullName,
            status: statusColor,
            userId,
          };
        }),

        paginationData: state.pendingCreatives.pagination,
        sort: state.pendingCreatives.sort,
      };
    },

    statusColorsList(state) {
      return state.statusColors;
    },
  },

  mutations: {
    SET_PENDING_CREATIVES(state, { data, meta, sort }) {
      if (data) state.pendingCreatives.data = data;
      if (meta) state.pendingCreatives.pagination = meta.pagination;
      if (sort) state.pendingCreatives.sort = sort;
    },
  },

  actions: {
    async getPendingCreatives(context, filters = {}) {
      const { page = 1, sort = context.state.pendingCreatives.sort, perPage = 15 } = filters;

      const query = {
        arrayQuery: [
          {
            method: "where",
            column: "approved",
            value: "pending",
          },
          {
            method: "orderBy",
            column: sort.column,
            direction: sort.direction,
          },
        ],
        page,
        perPage,
      };

      const { data, meta } = await CreativeAdminApi.get(query);

      context.commit("SET_PENDING_CREATIVES", { data, meta, sort });
    },

    async toggleCreativeActivity(context, data) {
      const { id, isActive, isAdminSide = false } = data;
      let status = isActive;

      await updateMethodApi(
        { id, isActive, isAdminSide },
        { withLoader: false, resultKeyOnSuccess: "data" }
      )
        .then(({ isActive }) => {
          status = isActive;
          isActive
            ? NotifyService.success("creativeActivated")
            : NotifyService.success("creativeStopped");
        })
        .catch((error) => {
          status = !isActive;
          NotifyService.error("actionFailed");

          return Promise.reject(error);
        });

      return status;
    },
  },
};

async function updateMethodApi({ id, isActive, isAdminSide }, settings) {
  return isAdminSide
    ? await CreativeAdminApi.update(id, { isActive }, settings)
    : await CreativeAdvertApi.update(id, { isActive }, settings);
}
