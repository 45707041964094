<template>
  <div class="input-images">
    <div class="input-images-header">
      <div v-if="label" class="label" :class="labelClass">
        {{ label }}
      </div>
      <div class="file-upload-button-wrapper">
        <IconSvg
          class="file-upload-button"
          name="Image"
          color="pale-sky"
          color-tag="fill"
          @click="onClickUploadFile"
        />
      </div>
    </div>
    <Draggable
      v-if="images.length"
      v-bind="{ animation: 200 }"
      v-model="images"
      :scroll-sensitivity="200"
      :force-fallback="true"
      :options="draggableOptions"
      class="images-container"
      :class="imageContainerGrid"
    >
      <template #item="{ element, index }">
        <div class="image-wrapper">
          <img :src="element.link" :alt="element.id" @click="onClickImage(index)" />
          <IconSvg class="delete-icon" name="Close" color="white" @click="deleteFile(element.id)" />
        </div>
      </template>
    </Draggable>

    <DragDrop ref="uppy" :uppy="uppy" class="uppy-upload" :class="uppyId" />

    <ErrorTooltip v-if="error" :error="error" />
  </div>
</template>

<script setup>
import Uppy from "@uppy/core";
import DragDrop from "@uppy/vue/lib/drag-drop";
DragDrop.compatConfig = { MODE: 3 };

import { IconSvg } from "@/components";
import ErrorTooltip from "@/components/ErrorTooltip";
import { defineProps, ref, computed, watch, defineEmits } from "vue";
import { getRandomId } from "@/mixins/global.mixin";
import store from "@/store";
import { openFancybox } from "@/plugins/helpers";
import "@fancyapps/ui/dist/fancybox.css";
import NotifyService from "@/services/notify.service";

const emit = defineEmits(["input"]);

const props = defineProps({
  value: {
    type: [Object, Array],
    default: () => [
      {
        id: 0,
        link: "",
      },
    ],
    required: true,
  },

  label: {
    type: String,
    default: "",
  },

  multiple: {
    type: Boolean,
    default: false,
  },

  maxFiles: {
    type: Number,
    default: 10,
  },

  allowedFileTypes: {
    type: Array,
    default: () => [".png", ".jpg", ".jpeg", ".gif"],
  },

  required: {
    type: Boolean,
    default: false,
  },

  error: {
    type: String,
    default: "",
  },

  maxFileSizeInBytes: {
    type: Number,
    default: 2097152,
  },
});

const isMultiple = computed(() => {
  return props.value instanceof Array && props.multiple;
});

const maxFilesNumber = computed(() => (isMultiple.value ? props.maxFiles : 1));

const uppy = computed({
  get() {
    return new Uppy({
      restrictions: {
        maxNumberOfFiles: maxFilesNumber.value,
        allowedFileTypes: props.allowedFileTypes,
      },
    }).on("file-added", (file) => {
      if (file.size > props.maxFileSizeInBytes) {
        NotifyService.error("theFileMayNotBeGreater");

        uppy.value.removeFile(file.id);

        return;
      }

      setFiles();
    });
  },
  set() {
    // setter is canceled
  },
});

let fileList = ref([]);

const breakpoint = computed(() => store.state["viewport/breakpoint"]);

const imageContainerGrid = computed(() => {
  let cols = props.value.length || 1;

  if (isMultiple.value) {
    if (breakpoint.value === "xs") cols = 1;

    if (breakpoint.value === "sm") {
      if (cols >= 2) cols = 2;
    }

    if (["md", "lg"].includes(breakpoint.value)) {
      if (cols >= 3) cols = 3;
    }

    if (["xl", "2xl"].includes(breakpoint.value)) {
      if (cols >= 4) cols = 4;
    }
  }

  return `grid-cols-${cols}`;
});

const draggableOptions = computed(() => {
  return {
    disabled: !isMultiple.value,
  };
});

const fancyboxItems = computed(() => {
  let data = [];

  if (isMultiple.value) data = props.value;

  if (!isMultiple.value) {
    if (Object.keys(props.value).length) {
      data = [props.value];
    }
  }

  return data.map(({ link }) => ({
    src: link,
    thumb: link,
  }));
});

const images = computed({
  get() {
    let data = props.value;

    if (!isMultiple.value) {
      data = [];

      if (Object.keys(props.value).length) {
        data.push(props.value);
      }
    }

    return data;
  },

  set(value) {
    let data = value;

    if (!isMultiple.value) {
      data = {};

      if (Object.keys(value).length) {
        [data] = value;
      }
    }

    emit("input", data);
  },
});

const uppyId = computed(() => `uppy-${getRandomId()}`);

const labelClass = computed(() => {
  return {
    "label-required": props.required,
  };
});

const uploadFiles = async () => {
  if (fileList.value.length) {
    const uploadFiles = fileList.value.map((file) => {
      return Promise.resolve(store.dispatch("file/uploadFile", file.data));
    });

    const uploadImages = await Promise.all(uploadFiles);

    images.value = (await isMultiple.value) ? images.value.concat(uploadImages) : uploadImages;

    fileList.value = [];
    uppy.value.cancelAll();
  }
};

watch(() => fileList.value, uploadFiles, { deep: true });

const onClickUploadFile = () => {
  document.querySelector(`.${uppyId.value} > .uppy-Root > button`).click();
};

const setFiles = () => {
  const { files } = uppy.value.getState();

  fileList.value = Object.values(files);
};

const deleteFile = async (id) => {
  const index = images.value.findIndex((file) => file.id === id);

  if (~index) {
    props.multiple ? images.value.splice(index, 1) : (images.value = []);
  }
};

const onClickImage = (index) => {
  openFancybox(fancyboxItems.value, index);
};
</script>

<style lang="postcss" scoped>
.input-images:deep() {
  @apply relative;
  @apply flex flex-col;
  @apply rounded-lg border border-dashed border-gray-300;
  @apply p-4;
  @apply relative;

  &:hover {
    @apply border-gray-400 bg-gray-50;
  }

  .input-images-header {
    @apply flex items-center justify-between;

    .label {
      @apply break-words;

      @apply text-pale-sky;
      @apply select-none text-sm;
      @apply flex space-x-1.5;
      @apply font-bold;
    }

    .file-upload-button-wrapper {
      @apply z-20;

      .file-upload-button {
        @apply cursor-pointer hover:opacity-75;
      }
    }
  }

  .images-container {
    @apply mt-4;
    @apply grid gap-4;
    @apply z-20;

    .image-wrapper {
      @apply cursor-pointer;
      @apply relative;

      .delete-icon {
        @apply absolute top-0 right-0;
        @apply bg-ebony-clay bg-opacity-50;
        @apply m-1 rounded;
      }
    }
  }

  .uppy-upload {
    @apply absolute top-0 left-0;
    @apply h-full w-full;
    @apply opacity-0;
    @apply z-10;

    .uppy-Root {
      @apply h-full;
    }
  }

  .label-required {
    &:after {
      @apply content-['*'];
      @apply ml-1 font-medium text-thunderbird;
    }
  }
}
</style>
