const URL_REG_EXP =
  // eslint-disable-next-line max-len
  /^((http|https):\/\/)?((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|[a-z\d]+([-.][a-z\d-]+)*\.[a-z\d-]{2,15})?(:\d{1,5})?)?((\/|\?)[^\r\n]*)?$/;

const DOMAIN_REG_EXP =
  /^((\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}|[a-z\d]+([-.][a-z\d]+)*\.[a-z]{2,5})?(:\d{1,5})?)$/;

const FLOAT_REG_EXP = /\d*\.?\d*/g;
const HUNDREDTHS_REG_EXP = /^(\d+)?([.]?\d{0,2})/g;
const INTEGER_REG_EXP = /\d*/g;
const PERCENTAGES_REG_EXP = /^[0-9][0-9]?$|^100$/g;

const ALPHA_NUM_UNDERSCORE_REG_EXP = /\w+/g;

const IFRAME_REG_EXP = /^<iframe[^>]*>\s*<\/iframe>/;
const SCRIPT_REG_EXP = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;

class ValidationService {
  PASSWORD_MIN_LENGTH = 6;
  PASSWORD_MAX_LENGTH = 25;

  ENDPOINT_NAME_MIN_LENGTH = 2;
  COMPANY_NAME_MIN_LENGTH = 2;

  CAMPAIGN_NAME_MIN_LENGTH = 2;
  CREATIVE_NAME_MIN_LENGTH = 2;

  LAST_NAME_MIN_LENGTH = 2;
  FIRST_NAME_MIN_LENGTH = 2;

  COMPANY_MIN_LENGTH = 2;
  PHONE_MIN_LENGTH = 9;
  SKYPE_MIN_LENGTH = 4;
  TELEGRAM_MIN_LENGTH = 5;

  DEPOSIT_MIN_VALUE = 100;
  DEPOSIT_MIN_VALUE_WIRE_TRANSFER = 500;
  DEPOSIT_MAX_LENGTH = 7;

  FLOAT_REG_EXP = FLOAT_REG_EXP;
  HUNDREDTHS_REG_EXP = HUNDREDTHS_REG_EXP;
  INTEGER_REG_EXP = INTEGER_REG_EXP;

  PERCENTAGES_REG_EXP = PERCENTAGES_REG_EXP;

  ALPHA_NUM_UNDERSCORE_REG_EXP = ALPHA_NUM_UNDERSCORE_REG_EXP;

  TMAX_MAX_VALUE = 10000;

  USER_AGE_RANGE = [18, 100];

  DAILY_BUDGET_MIN_VALUE = 20;
  DAILY_IMPRESSION_MIN_VALUE = 30000;

  CPM_MIN_VALUE = 0.35;
  CPM_MIN_ADULT_VALUE = 0.15;

  public url(value: string): boolean {
    return !!value?.trim().match(URL_REG_EXP);
  }

  public domain(value: string): boolean {
    return !!value?.trim().match(DOMAIN_REG_EXP);
  }

  public iframe(value: string): boolean {
    return !!value?.trim().match(IFRAME_REG_EXP);
  }

  public script(value: string): boolean {
    return !!value?.trim().match(SCRIPT_REG_EXP);
  }
}

export default new ValidationService();
