import i18n from "@/plugins/vue-i18n";

const AdminDashboard = () =>
  import(/* webpackChunkName: "adminDashboard" */ "@/views/admin/dashboard");

const dashboard = [
  {
    path: "dashboard",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: {
      title: i18n.t("title.dashboard"),
    },
  },
];

export default dashboard;
