import i18n from "@/plugins/vue-i18n";

const ResetPassword = () =>
  import(/* webpackChunkName: "auth" */ "@/views/auth/password-successfully-reset");

const passwordSuccessfullyReset = [
  {
    path: "/password-successfully-reset",
    name: "PasswordSuccessfullyReset",
    component: ResetPassword,
    meta: {
      title: i18n.t("title.passwordRecovery"),
    },
  },
];

export default passwordSuccessfullyReset;
