import i18n from "@/plugins/vue-i18n";

const Payments = () => import(/* webpackChunkName: "payments" */ "@/views/advert/payments/index");
const CapitalistHandler = () =>
  import(/* webpackChunkName: "payments" */ "@/views/advert/payments/capitalist-handler");

const payments = [
  {
    path: "/payments",
    name: "Payments",
    component: Payments,
    meta: {
      title: i18n.t("title.payments"),
    },
  },
  {
    path: "/payments/capitalist",
    name: "CapitalistHandler",
    component: CapitalistHandler,
  },
];

export default payments;
