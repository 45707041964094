import i18n from "@/plugins/vue-i18n";

const ThankYouPassword = () => import(/* webpackChunkName: "thanks" */ "@/views/thanks/Password");

const thankYouPassword = [
  {
    path: "/thank-you/password",
    name: "ThankYouPassword",
    component: ThankYouPassword,
    meta: {
      title: i18n.t("title.yourPasswordHasBeenSuccessfullyChanged"),
    },
  },
];

export default thankYouPassword;
