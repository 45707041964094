import FileApi from "@/api/files";

export default {
  namespaced: true,

  actions: {
    async uploadFile(context, file) {
      const formData = new FormData();

      formData.append("file", file);

      return await FileApi.upload(formData);
    },
  },
};
