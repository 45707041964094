import i18n from "@/plugins/vue-i18n";

const RussiaRestrictedError = () =>
  import(/* webpackChunkName: "error" */ "@/views/error/RussiaRestricted");

const internalServerError = [
  {
    path: "/restricted",
    name: "RussiaRestrictedError",
    component: RussiaRestrictedError,
    meta: {
      title: i18n.t("title.russiaRestrictedError"),
    },
  },
];

export default internalServerError;
