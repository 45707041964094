import i18n from "@/plugins/vue-i18n";
import { aggregates } from "@/data/traffic-loger";
import { trafficTypes, operationSystems } from "@/data/common";
import { camelCase } from "lodash";
import { browsers } from "@/data/campaign";

export default {
  namespaced: true,

  state: {
    aggregates,
    trafficTypes,
    operationSystems,
    browsers,
  },

  getters: {
    aggregateOptions(state) {
      return state.aggregates.map(({ code, numericCode }) => ({
        value: numericCode,
        code,
        label: i18n.t(`page.trafficLogger.aggregate.${code}`),
      }));
    },

    trafficTypeOptions(state) {
      return state.trafficTypes.map(({ code, numericCode }) => ({
        code: numericCode.toString(),
        label: i18n.t(`trafficType.${code}`),
      }));
    },

    operationSystemOptions(state) {
      return state.operationSystems.map(({ name }) => {
        return {
          code: name.toLowerCase(),
          label: name,
        };
      });
    },

    browserOptions(state) {
      return state.browsers.map((code) => ({
        code: code.toLowerCase(),
        label: i18n.t(`campaign.browser.${camelCase(code)}`),
      }));
    },
  },
};
