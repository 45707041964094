import articles from "./articles";
import faq from "./faq";
import emails from "./emails";

const ManagerLayout = () => import(/* webpackChunkName: "manager" */ "@/layouts/manager");

const managerLayout = [
  {
    path: "/manager",
    name: "ManagerLayout",
    component: ManagerLayout,
    redirect: { name: "Articles" },
    children: [...articles, ...faq, ...emails],
  },
];

export default managerLayout;
