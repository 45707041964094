/**
 * Coded by Andrew Kot.
 * Date: 27/01/2023
 * Time: 17:49
 */

export default class QueryBuilder {
  public query: ArrayQuery = [];

  public orderBy(column: string, direction: Direction) {
    this.query.push({
      method: "orderBy",
      column,
      direction,
    });

    return this;
  }

  public orderByJoin(
    relation: string,
    column: string,
    direction: Direction,
    aggregation?: Aggregation
  ) {
    this.query.push({
      method: "orderByJoin",
      relation,
      column,
      direction,
      aggregation,
    });

    return this;
  }

  public where(column: string, operator: Operator, value: Value, group?: string) {
    this.query.push({
      method: "where",
      column,
      operator,
      value,
      group,
    });

    return this;
  }

  public orWhere(column: string, operator: Operator, value: Value, group?: string) {
    this.query.push({
      method: "orWhere",
      column,
      operator,
      value,
      group,
    });

    return this;
  }

  public whereDate(column: string, operator: Operator, value: Value) {
    this.query.push({
      method: "whereDate",
      column,
      operator,
      value,
    });

    return this;
  }

  public whereIn(column: string, value: Array<Value>) {
    this.query.push({
      method: "whereIn",
      column,
      value,
    });

    return this;
  }

  public whereNotIn(column: string, value: Array<Value>) {
    this.query.push({
      method: "whereNotIn",
      column,
      value,
    });

    return this;
  }

  public has(relation: string, operator?: Operator, count?: number) {
    this.query.push({
      method: "has",
      relation,
      operator,
      count,
    });

    return this;
  }

  public whereHas(
    relation: string,
    column: string,
    operator: Operator,
    value: Value,
    group?: string
  ) {
    this.query.push({
      method: "whereHas",
      relation,
      column,
      operator,
      value,
      group,
    });

    return this;
  }

  public orWhereHas(
    relation: string,
    column: string,
    operator: Operator,
    value: Value,
    group?: string
  ) {
    this.query.push({
      method: "orWhereHas",
      relation,
      column,
      operator,
      value,
      group,
    });

    return this;
  }

  public whereHasIn(relation: string, column: string, value: Array<Value>) {
    this.query.push({
      method: "whereHasIn",
      relation,
      column,
      value,
    });

    return this;
  }

  public whereJsonContains(column: string, value: Value) {
    this.query.push({
      method: "whereJsonContains",
      column,
      value,
    });

    return this;
  }

  public having(column: string, operator: Operator, value: Value, group?: string) {
    this.query.push({
      method: "having",
      column,
      operator,
      value,
      group,
    });

    return this;
  }

  public havingIn(column: string, value: Array<Value>) {
    this.query.push({
      method: "havingIn",
      column,
      value,
    });

    return this;
  }
}

export interface ListQuery {
  arrayQuery?: ArrayQuery;
  page?: number;
  perPage?: number;
}

export interface ArrayQuery extends Array<any> {
  [index: number]:
    | OrderBy
    | OrderByJoin
    | Where
    | OrWhere
    | WhereDate
    | Has
    | WhereHas
    | OrWhereHas
    | WhereHasIn
    | WhereIn
    | WhereNotIn
    | WhereJsonContains
    | Having
    | HavingIn;
}

type Direction = "asc" | "desc";

type Aggregation = "count" | "avg" | "sum" | "min" | "max";

type Operator = "=" | "!=" | ">=" | "<=" | ">" | "<" | "like" | "not like";

interface OrderBy {
  method: "orderBy";
  column: string;
  direction: Direction;
}

interface OrderByJoin {
  method: "orderByJoin";
  relation: string;
  column: string;
  direction: Direction;
  aggregation?: Aggregation;
}

interface Where {
  method: "where";
  column: string;
  operator?: Operator;
  value: Value;
  group?: string;
}

interface OrWhere {
  method: "orWhere";
  column: string;
  operator?: Operator;
  value: Value;
  group?: string;
}

interface WhereDate {
  method: "whereDate";
  column: string;
  operator?: Operator;
  value: Value;
}

interface WhereIn {
  method: "whereIn";
  column: string;
  value: Array<Value>;
}

interface WhereNotIn {
  method: "whereNotIn";
  column: string;
  value: Array<Value>;
}

interface Has {
  method: "has";
  relation: string;
  operator?: Operator;
  count?: number;
}

interface WhereHas {
  method: "whereHas";
  relation: string;
  column: string;
  operator?: Operator;
  value: Value;
  group?: string;
}

interface OrWhereHas {
  method: "orWhereHas";
  relation: string;
  column: string;
  operator?: Operator;
  value: Value;
  group?: string;
}

interface WhereHasIn {
  method: "whereHasIn";
  relation: string;
  column: string;
  value: Array<Value>;
}

interface WhereJsonContains {
  method: "whereJsonContains";
  column: string;
  value: Value;
}

interface HavingIn {
  method: "havingIn";
  column: string;
  value: Array<Value>;
}

interface Having {
  method: "having";
  column: string;
  operator?: Operator;
  value: Value;
  group?: string;
}

type Value = string | number | boolean | null;
