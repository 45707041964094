import i18n from "@/plugins/vue-i18n";

const Emails = () => import(/* webpackChunkName: "emails" */ "@/views/manager/emails/index");
const EmailAdd = () => import(/* webpackChunkName: "emails" */ "@/views/manager/emails/add");
const EmailEdit = () => import(/* webpackChunkName: "emails" */ "@/views/manager/emails/edit");
const EmailCategories = () =>
  import(/* webpackChunkName: "articlesCategories" */ "@/views/manager/emails/categories/index");
const EmailCategoriesAdd = () =>
  import(/* webpackChunkName: "articlesCategories" */ "@/views/manager/emails/categories/add");
const EmailCategoriesEdit = () =>
  import(/* webpackChunkName: "articlesCategories" */ "@/views/manager/emails/categories/edit");
const EmailLogsDetails = () =>
  import(/* webpackChunkName: "articlesCategories" */ "@/views/manager/emails/logs-details");

const emails = [
  {
    path: "emails",
    name: "Emails",
    component: Emails,
    meta: {
      title: i18n.t("title.emails"),
    },
  },
  {
    path: "emails/add",
    name: "EmailAdd",
    component: EmailAdd,
    meta: {
      title: i18n.t("title.emails"),
    },
  },
  {
    path: "emails/:id/edit",
    name: "EmailEdit",
    component: EmailEdit,
    meta: {
      title: i18n.t("title.emails"),
    },
  },
  {
    path: "emails/categories",
    name: "EmailsCategories",
    component: EmailCategories,
    meta: {
      title: i18n.t("title.categories"),
    },
  },
  {
    path: "emails/categories/add",
    name: "EmailsCategoriesAdd",
    component: EmailCategoriesAdd,
    meta: {
      title: i18n.t("title.categories"),
    },
  },
  {
    path: "emails/categories/:id/edit",
    name: "EmailsCategoriesEdit",
    component: EmailCategoriesEdit,
    meta: {
      title: i18n.t("title.categories"),
    },
  },
  {
    path: "emails/logs-details/:id",
    name: "EmailLogsDetails",
    component: EmailLogsDetails,
    meta: {
      title: i18n.t("title.emails"),
    },
  },
];

export default emails;
