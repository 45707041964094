import i18n from "@/plugins/vue-i18n";

const Articles = () => import(/* webpackChunkName: "articles" */ "@/views/manager/articles/index");
const ArticleAdd = () => import(/* webpackChunkName: "articles" */ "@/views/manager/articles/add");
const ArticleEdit = () =>
  import(/* webpackChunkName: "articles" */ "@/views/manager/articles/edit");
const ArticlesCategories = () =>
  import(/* webpackChunkName: "articlesCategories" */ "@/views/manager/articles/categories/index");
const ArticlesCategoriesAdd = () =>
  import(
    /* webpackChunkName: "articlesCategories" */ "@/views/manager/articles/categories/add/add"
  );
const ArticlesCategoriesEdit = () =>
  import(/* webpackChunkName: "articlesCategories" */ "@/views/manager/articles/categories/edit");

const articles = [
  {
    path: "articles",
    name: "Articles",
    component: Articles,
    meta: {
      title: i18n.t("title.articles"),
    },
  },
  {
    path: "articles/add",
    name: "ArticleAdd",
    component: ArticleAdd,
    meta: {
      title: i18n.t("title.articles"),
    },
  },
  {
    path: "articles/:id/edit",
    name: "ArticleEdit",
    component: ArticleEdit,
    meta: {
      title: i18n.t("title.articles"),
    },
  },
  {
    path: "articles/categories",
    name: "ArticlesCategories",
    component: ArticlesCategories,
    meta: {
      title: i18n.t("title.categories"),
    },
  },
  {
    path: "articles/categories/add",
    name: "ArticlesCategoriesAdd",
    component: ArticlesCategoriesAdd,
    meta: {
      title: i18n.t("title.categories"),
    },
  },
  {
    path: "articles/categories/:id/edit",
    name: "ArticlesCategoriesEdit",
    component: ArticlesCategoriesEdit,
    meta: {
      title: i18n.t("title.categories"),
    },
  },
];

export default articles;
