const ConfirmAccount = () => import(/* webpackChunkName: "auth" */ "@/views/auth/confirm-account");

const confirmAccount = [
  {
    path: "/confirm-account/:token",
    name: "ConfirmAccount",
    component: ConfirmAccount,
  },
];

export default confirmAccount;
