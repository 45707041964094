import ApiService from "@/services/api.service";
import axios from "axios";
import { IGetCountryByIpResponse } from "@/models/dictionary";

const api = new ApiService();

class DictionaryApi {
  public async countries(): Promise<IGetCountryByIpResponse> {
    return await api.post("/dictionary/countries", { locale: "en" });
  }

  public async getCountryUser(): Promise<string> {
    return await api.post("/dictionary/getCountryUser");
  }

  public async getCountryByIp(): Promise<IGetCountryByIpResponse> {
    const url = "https://extreme-ip-lookup.com/json";

    return await axios.post(url).then(({ data }) => data);
  }
}

export default new DictionaryApi();
