import i18n from "@/plugins/vue-i18n";

const PromoCodes = () =>
  import(/* webpackChunkName: "promo-codes" */ "@/views/admin/promo-codes/index");
const PromoCodeUsages = () =>
  import(/* webpackChunkName: "promo-codes" */ "@/views/admin/promo-codes/usages");

const promoCodes = [
  {
    path: "promo-codes",
    name: "PromoCodes",
    component: PromoCodes,
    meta: {
      title: i18n.t("title.promoCodes"),
    },
  },
  {
    path: "promo-codes/:id/",
    name: "PromoCodeUsages",
    component: PromoCodeUsages,
    meta: {
      title: i18n.t("title.promoCodeUsages"),
    },
  },
];

export default promoCodes;
