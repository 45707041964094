import * as t from "io-ts";
import { tMeta } from "@/models/common";
import { tConversionPixel } from "@/models/conversion-pixel";

export interface IUser {
  id: number | null;
  firstName: string;
  lastName: string;
  userId?: number | null;
  role: string;
  address: string;
  phone: string;
  skype: string;
  country: string;
  company: string;
  email: string;
  balance: number;
  isOnline?: boolean;
  lastLogin: string;
  creativesCount?: number;
  campaignsCount?: number;
  salesManager?: IUser;
  accountManager: IUser;
  managerComment?: string | null;
  telegram?: string;
  createdAt: string;
  totalSpent?: number;
  lastActivity?: {
    action: string;
    object: string;
    entityId: number;
    createdAt: string;
  };
  isFavorite?: boolean;
}

export interface IGetUsersQuery {
  page?: number;
  append?: string;
  searchFields?: string;
  search?: string;
  searchJoin?: string;
  orderBy?: string;
  sortedBy?: string;
}

export const tGetUsersQuery = t.strict({
  page: t.number,
  append: t.string,
  searchFields: t.string,
  search: t.string,
  searchJoin: t.string,
  orderBy: t.string,
  sortedBy: t.string,
});

export interface IDeleteUserQuery {
  wipePersonalInfo: boolean;
}

export interface IUserChangePasswordRequest {
  password: string;
}

export interface IUserChangePasswordByTokenParams {
  password: string;
  password_confirmation: string;
}

export const tUser = t.strict({
  id: t.number,
  isCannabisAdvert: t.boolean,
  isGamblingAdvert: t.boolean,
  isMalwareAdvert: t.boolean,
  isPremiumAdvert: t.boolean,
  allowRefill: t.boolean,
  firstName: t.string,
  lastName: t.union([t.string, t.null]),
  role: t.string,
  isFinanceRole: t.boolean,
  email: t.string,
  company: t.union([t.string, t.null]),
  country: t.union([t.string, t.null]),
  address: t.null,
  phone: t.union([t.string, t.null]),
  telegram: t.union([t.string, t.null]),
  skype: t.union([t.string, t.null]),
  emailSpare: t.null,
  firstPaymentAmount: t.number,
  followingPaymentAmount: t.number,
  minBalanceNotification: t.number,
  markup: t.number,
  autoApprovePayments: t.union([t.boolean, t.number]),
  canSeeClick: t.boolean,
  canSeeSsp: t.boolean,
  canSeeSspPremium: t.boolean,
  isMinPriceCancellation: t.boolean,
  prepay: t.union([t.boolean, t.number]),
  balance: t.number,
  lowBudgetSum: t.null,
  isOnline: t.boolean,
  isFavorite: t.boolean,
  ivtPx: t.number,
  useUnscanned: t.number,
  isFirstPayment: t.union([t.boolean, t.undefined]),
  createdAt: t.string,
  lastLogin: t.union([t.string, t.null]),
  isActive: t.boolean,
  advertDailyLimit: t.number,
  managerComment: t.union([t.string, t.null]),
  salesManagerId: t.null,
  sendReport: t.boolean,
  lowBudgetNotification: t.boolean,
  zeroBudgetNotification: t.boolean,
  balanceChangeNotification: t.boolean,
  creativeApprovalNotification: t.boolean,
  accountManagerId: t.union([t.number, t.null]),
  updatedAt: t.string,
  creativesCount: t.number,
  campaignsCount: t.union([t.number, t.undefined]),
  totalSpent: t.union([t.string, t.number, t.undefined]),
  conversionPostbackUrl: t.union([tConversionPixel, t.undefined]),
});

export const tUsersStatistic = t.strict({
  onlineUsersCount: t.number,
  newUsersCount: t.number,
  lowBalanceCount: t.number,
  inactiveUsersCount: t.number,
  averageCPM: t.number,
  dailyProfit: t.number,
});

export const tUsers = t.strict({
  data: t.array(tUser),
  meta: tMeta,
});

export const tUsersAll = t.array(
  t.strict({
    label: t.string,
    id: t.number,
  })
);

export const tCheckPassword = t.strict({
  isValidPassword: t.boolean,
});

export const tCheckPasswordParams = t.strict({
  password: t.string,
});

export type User = t.TypeOf<typeof tUser>;
export type Users = t.TypeOf<typeof tUsers>;
export type UsersStatistics = t.TypeOf<typeof tUsersStatistic>;
export type GetUsersQuery = t.TypeOf<typeof tGetUsersQuery>;
export type CheckPassword = t.TypeOf<typeof tCheckPassword>;
export type CheckPasswordParams = t.TypeOf<typeof tCheckPasswordParams>;
