const colors = require("tailwindcss/colors");

module.exports = {
  content: [
    "./index.html",
    "./src/**/*.{vue,js,ts,jsx,tsx}",
    "node_modules/vue-tailwind/dist/*.js",
  ],
  theme: {
    fontFamily: {
      roboto: ["Roboto", "sans-serif"],
    },
    extend: {
      fontSize: {
        "2xs": ["0.625rem", "0.75rem"], //           10px
        xs: ["0.75rem", "0.875rem"], //              12px
        sm: ["0.875rem", "1rem"], //                 14px
        base: ["1rem", "1.1875rem"], //              16px
        lg: ["1.125rem", "1.3125rem"], //            18px
      },
      opacity: {
        6: ".6",
        15: ".15",
        45: ".45",
      },
      screens: {
        "xl-max": { max: "1535px" },
        "lg-max": { max: "1279px" },
        "md-max": { max: "1023px" },
        "sm-max": { max: "767px" },
      },
      boxShadow: {
        outer: "0px 3.5px 10px rgba(0, 0, 0, 0.07)",
        "inner-1": "inset 0px 2px 1px rgba(32, 46, 120, 0.539034)",
        "inner-2": "inset 0px 1px 0px rgba(255, 255, 255, 0.06)",
      },
    },
    colors: {
      inherit: "inherit",
      transparent: "transparent",
      current: "currentColor",
      neutral: colors.neutral,
      white: colors.white,
      black: colors.black,
      gray: colors.gray,
      green: colors.green,
      red: colors.red,
      purple: colors.purple,
      blue: colors.blue,

      "athens-gray": {
        1: "#F4F6F8",
        2: "#DFE3E8",
        3: "#F9FAFB",
        4: "#F5F6F8",
      },
      "ebony-clay": "#212B36",
      "pale-sky": "#637381",
      jaffa: {
        1: "#EA8B4D",
        2: "#F2994A",
      },
      mystic: "#DDE1E9",
      "dodger-blue": "#1FC9FF",
      "peach-orange": "#FFBE94",
      sauvignon: "#FFF7F5",
      trinidad: "#E85C00",
      "oslo-gray": "#90959B",
      gallery: "#F0F0F0",
      "blaze-orange": "#FF6C0C",
      "dark-blue": "#200E32",
      "jungle-green": "#27AE60",
      "burnt-sienna": "#EB5757",
      porcelain: "#F3F5F6",
      thunderbird: "#DE3617",
      mirage: "#14142B",
      "silver-chalice": "#AAAAAA",
      "dusty-gray": "#999999",
      "de-york": "#6FCF97",
      heather: "#C2CAD8",
      mercury: "#E5E5E5",
      silver: "#BDBDBD",
      "pale-gray": "#E1DCDC",
      "rockman-blue": "#3A90F6",
      hollandaise: "#FFEC44",
    },
  },
  plugins: [require("@tailwindcss/forms"), require("@tailwindcss/typography")],
};
