export const timezones = [
  -12, -11, -10, -9, -8, -7, -6, -5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5, 5.5, 6, 7, 8, 9, 10, 11, 12,
];

export const trafficTypes = [
  {
    code: "inApp",
    numericCode: 3,
  },
  {
    code: "mobileWeb",
    numericCode: 1,
  },
  {
    code: "desktopWeb",
    numericCode: 2,
  },
  {
    code: "rewardedVideo",
    numericCode: 5,
  },
];

export const operationSystems = [
  {
    name: "Android",
    versions: ["8.0", "8.1", "9.0", "10.0", "11.0", "12.0", "12.1", "13.0", "14.0", "15.0"],
  },
  {
    name: "iOS",
    versions: [
      "10.0",
      "10.1",
      "10.2",
      "10.3",
      "11.0",
      "11.1",
      "11.2",
      "11.3",
      "12.0",
      "12.1",
      "12.2",
      "12.3",
      "12.4",
      "13.1",
      "13.2",
      "13.3",
      "13.4",
      "13.5",
      "13.6",
      "14.0",
      "14.1",
      "14.2",
      "14.3",
      "14.4",
      "14.5",
      "14.6",
      "14.7",
      "14.8",
      "15.0",
      "15.1",
      "15.2",
      "15.3",
      "15.4",
      "15.5",
      "15.6",
      "15.7",
      "16.0",
      "16.1",
      "16.2",
      "16.3",
      "16.4",
      "16.5",
      "16.6",
      "17.0",
      "17.1",
      "17.2",
      "17.3",
    ],
  },
  {
    name: "Mac OS",
    versions: [
      "10.10",
      "10.11",
      "10.12",
      "10.13",
      "10.14",
      "10.15",
      "11.0",
      "11.1",
      "11.2",
      "11.3",
      "11.4",
      "11.5",
      "11.6",
      "12.0",
      "12.1",
      "12.2",
      "12.3",
      "12.4",
      "12.5",
      "12.6",
      "13.0",
      "13.1",
      "13.2",
      "13.3",
      "13.4",
      "13.5",
      "14.0",
      "14.1",
      "14.2",
      "14.3",
    ],
  },
  {
    name: "BlackBerry",
    versions: [],
  },
  {
    name: "Linux",
    versions: [],
  },
  {
    name: "Windows",
    versions: ["8.1", "10", "11"],
  },
  {
    name: "Windows Mobile",
    versions: [],
  },
  {
    name: "Symbian",
    versions: ["9.1", "9.2", "9.3", "9.4"],
  },
];

export const types = [
  "banner",
  "native",
  // "interstitial",
  // "push",
  "pop",
  "video",
];

export const deviceBrands = [
  "acer",
  "alcatel",
  "alcatel one touch",
  "amazon",
  "apple",
  "asus",
  "dell",
  "google",
  "htc",
  "huawei",
  "lenovo",
  "lg",
  "motorola",
  "oneplus",
  "rca",
  "samsung",
  "sony",
  "sony ericsson",
  "verizon",
  "xiaomi",
  "zte",
];

export const connectionTypes = ["wifi", "ethernet", "cellular_all", "cellular_3g", "cellular_4g"];

export const browsers = [
  "Chrome",
  "Edge",
  "IE",
  "Safari",
  "Mozilla",
  "Opera",
  "UCBrowser",
  "Yandex",
  "Android",
];

export const languages = [
  "ab",
  "aa",
  "af",
  "sq",
  "am",
  "ar",
  "an",
  "hy",
  "as",
  "ay",
  "az",
  "ba",
  "eu",
  "bn",
  "dz",
  "bh",
  "bi",
  "br",
  "bg",
  "my",
  "be",
  "km",
  "ca",
  "zh",
  "zh-Hans",
  "zh-Hant",
  "co",
  "hr",
  "cs",
  "da",
  "nl",
  "en",
  "eo",
  "et",
  "fo",
  "fa",
  "fj",
  "fi",
  "fr",
  "fy",
  "gl",
  "gd",
  "gv",
  "ka",
  "de",
  "el",
  "kl",
  "gn",
  "gu",
  "ht",
  "ha",
  "he",
  "iw",
  "hi",
  "hu",
  "is",
  "io",
  "id",
  "in",
  "ia",
  "ie",
  "iu",
  "ik",
  "ga",
  "it",
  "ja",
  "jv",
  "kn",
  "ks",
  "kk",
  "rw",
  "ky",
  "rn",
  "ko",
  "ku",
  "lo",
  "la",
  "lv",
  "li",
  "ln",
  "lt",
  "mk",
  "mg",
  "ms",
  "ml",
  "mt",
  "mi",
  "mr",
  "mo",
  "mn",
  "na",
  "ne",
  "no",
  "oc",
  "or",
  "om",
  "ps",
  "pl",
  "pt",
  "pa",
  "qu",
  "rm",
  "ro",
  "ru",
  "sm",
  "sg",
  "sa",
  "sr",
  "sh",
  "st",
  "tn",
  "sn",
  "ii",
  "sd",
  "si",
  "ss",
  "sk",
  "sl",
  "so",
  "es",
  "su",
  "sw",
  "sv",
  "tl",
  "tg",
  "ta",
  "tt",
  "te",
  "th",
  "bo",
  "ti",
  "to",
  "ts",
  "tr",
  "tk",
  "tw",
  "ug",
  "uk",
  "ur",
  "uz",
  "vi",
  "vo",
  "wa",
  "cy",
  "wo",
  "xh",
  "yi",
  "ji",
  "yo",
  "zu",
];

export const statuses = ["pending", "approved", "disapproved", "disabled"];

export const iabCategories = [
  "IAB1",
  "IAB2",
  "IAB3",
  "IAB4",
  "IAB5",
  "IAB6",
  "IAB7",
  "IAB8",
  "IAB9",
  "IAB10",
  "IAB11",
  "IAB12",
  "IAB13",
  "IAB14",
  "IAB15",
  "IAB16",
  "IAB17",
  "IAB18",
  "IAB19",
  "IAB20",
  "IAB21",
  "IAB22",
  "IAB23",
  "IAB24",
  "IAB25",
];

export const deviceTypes = ["smartphone", "tablet", "ctv"];

export const mimeTypes = [
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/x-flv",
  "video/x-ms-wmv",
  "video/webm",
];
