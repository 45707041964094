import i18n from "@/plugins/vue-i18n";

const ChangePassword = () => import(/* webpackChunkName: "auth" */ "@/views/auth/change-password");

const changePassword = [
  {
    path: "/change-password/:token",
    name: "ChangePassword",
    component: ChangePassword,
    meta: {
      title: i18n.t("title.changePassword"),
    },
  },
];

export default changePassword;
