import { TranslateResult } from "vue-i18n";
import * as t from "io-ts";

export const tConversionPixel = t.strict({
  code: t.string,
  conversionType: t.string,
  createdAt: t.union([t.string, t.null]),
  frequency: t.number,
  id: t.number,
  isActive: t.boolean,
  name: t.string,
  repeatConversionsType: t.string,
  updatedAt: t.union([t.string, t.null]),
  userId: t.number,
});

export interface ICreateConversionPixelParams {
  name: TranslateResult | string;
  conversionType: string;
  repeatConversionsType: string;
  frequency: number;
}

export type ConversionPixel = t.TypeOf<typeof tConversionPixel>;
