import Vue from "vue";
import i18n from "@/plugins/vue-i18n";
import { TranslateResult } from "vue-i18n";

class NotifyService {
  public DURATION_SHORT = 4000;
  public DURATION_LONG = 12000;

  public notify(settings: ISettings) {
    const { type, code, text, duration } = settings;

    Vue.notify({
      group: "notify",
      type,
      text: text ? text.toString() : prepareMessage(type, code),
      duration: duration || this.DURATION_SHORT,
    });
  }

  public success(code?: string, duration = this.DURATION_SHORT) {
    this.notify({
      type: "success",
      code: code,
      duration: duration,
    });
  }

  public error(code?: string, duration = this.DURATION_LONG) {
    this.notify({
      type: "error",
      code: code,
      duration: duration,
    });
  }
}

interface ISettings {
  type: string;
  code?: string;
  text?: TranslateResult | string;
  duration?: number;
}

function prepareMessage(type: string, code?: string): string {
  const message = i18n.te(`component.notify.${type}.${code}`)
    ? `component.notify.${type}.${code}`
    : `component.notify.${type}.default`;

  return i18n.t(message).toString();
}

export default new NotifyService();
