import { parse, format } from "date-fns";

export const BACKEND_DATE_FORMAT = "yyyy-MM-dd";
export const BACKEND_DATETIME_FORMAT = "yyyy-MM-dd HH:mm:ss";
export const BACKEND_TIME_FORMAT = "HH:mm:ss";
export const FRONTEND_DATE_FORMAT = "MM/dd/yyyy";
export const FRONTEND_DATETIME_FORMAT = "MM/dd/yyyy hh:mm a";
export const FRONTEND_TIME_FORMAT = "hh:mm a";
export const FRONTEND_NOTIFICATION_DATETIME_FORMAT = "PPPPp";
export const BACKEND_DATETIME_MICROUTC_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSSSSS'Z'";

class DateService {
  public convertDate(
    value: string,
    formatIn: string = BACKEND_DATE_FORMAT,
    formatOut: string = FRONTEND_DATE_FORMAT
  ): string {
    return value ? format(parse(value, formatIn, new Date()), formatOut) : "";
  }

  public formatDate(date: Date, pattern: string = BACKEND_DATE_FORMAT): string {
    return format(date, pattern);
  }
}

export default new DateService();
