import i18n from "@/plugins/vue-i18n";

const ThankYouEmail = () => import(/* webpackChunkName: "thanks" */ "@/views/thanks/Email");

const thankYouEmail = [
  {
    path: "/thank-you/email",
    name: "ThankYouEmail",
    component: ThankYouEmail,
    meta: {
      title: i18n.t("title.yourEmailIsNowConfirmed"),
    },
  },
];

export default thankYouEmail;
