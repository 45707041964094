import DictionaryApi from "@/api/dictionary";

export default {
  namespaced: true,

  state: {
    countries: [],
    defaultCountry: "US", // TODO: improve geo detection
  },

  getters: {
    getCountriesList(state) {
      return state.countries;
    },

    getDefaultCountry(state) {
      return state.defaultCountry;
    },
  },

  mutations: {
    SET_COUNTRIES(state, countries) {
      state.countries = Object.keys(countries).map((code) => ({
        code,
        label: countries[code],
      }));
    },

    SET_DEFAULT_COUNTRY(state, country) {
      state.defaultCountry = country;
    },
  },

  actions: {
    async getCountries(context) {
      if (!context.state.countries.length) {
        const response = await DictionaryApi.countries();

        context.commit("SET_COUNTRIES", response);
      }
    },

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async getDefaultCountry(context) {
      // TODO: improve GEO detection
      // if (!context.state.defaultCountry.length) {
      //
      //   let country = await DictionaryApi.getCountryUser();
      //
      //   if (!country.length) {
      //     const { countryCode } = await DictionaryApi.getCountryByIp();
      //
      //     country = countryCode;
      //   }
      //
      //   if (country.length) {
      //     context.commit("SET_DEFAULT_COUNTRY", country);
      //   }
      // }
    },
  },
};
