import ApiService from "@/services/api.service";
import { ISettings } from "@/models/common";
import { Notifications, tNotifications } from "@/models/notification";

const api = new ApiService();

class NotificationApi {
  public async new(
    settings: ISettings = { withLoader: false, tModel: tNotifications }
  ): Promise<Notifications> {
    return await api.get("/notifications/new", settings);
  }

  public async read(id: number): Promise<Notifications> {
    return await api.patch(`/notifications/${id}`);
  }

  public async readAll(): Promise<Notifications> {
    return await api.post("/notifications");
  }
}

export default new NotificationApi();
