import i18n from "@/plugins/vue-i18n";

const SignUp = () => import(/* webpackChunkName: "auth" */ "@/views/auth/sign-up");

const signUp = [
  {
    path: "/sign-up",
    name: "SignUp",
    component: SignUp,
    meta: {
      title: i18n.t("title.signUp"),
    },
  },
];

export default signUp;
