export default {
  namespaced: true,

  state: {
    perPageList: [10, 15, 20, 50, 100],
  },

  getters: {
    perPageOptions(state) {
      return state.perPageList.map((item) => {
        return { label: item, value: item };
      });
    },
  },
};
