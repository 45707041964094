import { ResponseType } from "axios";
import * as t from "io-ts";
import { TypeC } from "io-ts";

export interface IResources {
  data: Array<object>;
  meta: IMeta;
}

export interface IMeta {
  pagination: {
    total: number;
    count: number;
    per_page: number;
    current_page: number;
    total_pages: number;
  };
}

export interface ICheckTargetUrlResponse {
  isSecure: boolean;
}

export interface ICheckIframeResponse {
  isSecure: boolean;
}

export interface ISettings {
  withLoader?: boolean;
  resultKeyOnSuccess?: string;
  withAxiosResponseOnSuccess?: boolean;
  showErrors?: boolean;
  responseType?: ResponseType;
  tModel?: t.ExactC<any> | t.ArrayC<any> | t.IntersectionC<any> | TypeC<any>;
}

export interface ICreateUserQuery {
  fromUserPage: boolean;
}

// tModels
export const tMeta = t.strict({
  pagination: t.strict({
    total: t.number,
    count: t.number,
    per_page: t.number,
    current_page: t.number,
    total_pages: t.number,
    links: t.union([
      t.strict({
        next: t.union([t.string, t.undefined]),
        previous: t.union([t.string, t.undefined]),
      }),
      t.undefined,
      t.unknown, // empty array
    ]),
  }),
});

export interface IdResponse {
  id: number;
}
