import i18n from "@/plugins/vue-i18n";
import { types } from "@/data/promo-codes";

export default {
  namespaced: true,

  state: {
    types,
  },

  getters: {
    typesList(state) {
      return state.types.map((code) => ({
        code: code,
        label: i18n.t(`page.promoCodes.types.${code}`),
      }));
    },
  },
};
