import i18n from "@/plugins/vue-i18n";

const AdminCreatives = () =>
  import(/* webpackChunkName: "adminCreatives" */ "@/views/admin/creatives");

const AdminCreativeEdit = () =>
  import(/* webpackChunkName: "adminCreatives" */ "@/views/admin/creatives/edit");

const creatives = [
  {
    path: "creatives",
    name: "AdminCreatives",
    component: AdminCreatives,
    meta: {
      title: i18n.t("title.creatives"),
    },
  },
  {
    path: "creatives/:id/edit",
    name: "AdminCreativeEdit",
    component: AdminCreativeEdit,
    meta: {
      title: i18n.t("title.creative"),
    },
  },
];

export default creatives;
