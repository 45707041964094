import i18n from "@/plugins/vue-i18n";

const Audience = () => import(/* webpackChunkName: "audience" */ "@/views/advert/audience/index");
const AudienceTargeting = () =>
  import(/* webpackChunkName: "audience" */ "@/views/advert/audience/targeting");
const AudienceAdd = () => import(/* webpackChunkName: "audience" */ "@/views/advert/audience/add");
const AudienceEdit = () =>
  import(/* webpackChunkName: "audience" */ "@/views/advert/audience/edit");

const audience = [
  {
    path: "/audience",
    name: "Audience",
    component: Audience,
    meta: {
      title: i18n.t("title.audience"),
    },
  },
  {
    path: "/audience/targeting",
    name: "AudienceTargeting",
    component: AudienceTargeting,
    meta: {
      title: i18n.t("title.audience"),
    },
  },
  {
    path: "/audience/add",
    name: "AudienceAdd",
    component: AudienceAdd,
    meta: {
      title: i18n.t("title.audience"),
    },
  },
  {
    path: "/audience/:id/edit",
    name: "AudienceEdit",
    component: AudienceEdit,
    meta: {
      title: i18n.t("title.audience"),
    },
  },
];

export default audience;
