import i18n from "@/plugins/vue-i18n";

const InternalServerError = () =>
  import(/* webpackChunkName: "error" */ "@/views/error/InternalServerError");

const internalServerError = [
  {
    path: "/500",
    name: "InternalServerError",
    component: InternalServerError,
    meta: {
      title: i18n.t("title.internalServerError"),
    },
  },
];

export default internalServerError;
