class JwtService {
  AUTH_TOKEN = "token";
  AUTH_TOKEN_EXPIRES = "token-expires";
  SUDO_USER = "sudo-user";

  public getAuthToken() {
    return window.localStorage.getItem(this.AUTH_TOKEN);
  }

  public getAuthTokenExpiresTime() {
    return window.localStorage.getItem(this.AUTH_TOKEN_EXPIRES);
  }

  public saveAuthToken(token: string, expires: number) {
    window.localStorage.setItem(this.AUTH_TOKEN, token);
    window.localStorage.setItem(this.AUTH_TOKEN_EXPIRES, expires.toString());
  }

  public destroyAuthToken() {
    window.localStorage.removeItem(this.AUTH_TOKEN);
    window.localStorage.removeItem(this.AUTH_TOKEN_EXPIRES);
  }

  public getSudoUser() {
    return window.localStorage.getItem(this.SUDO_USER);
  }

  public setSudoUser(token: string) {
    window.localStorage.setItem(this.SUDO_USER, token);
  }

  public removeSudoUser() {
    window.localStorage.removeItem(this.SUDO_USER);
  }
}

export default new JwtService();
