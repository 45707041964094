import Meta from "@/router/meta";

export default (to, from) => {
  // Meta data service
  Meta.setDocumentMeta(to, from);

  // Scroll up when all page is loaded
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
};
