import i18n from "@/plugins/vue-i18n";

const ManagerFrequentlyAskedQuestions = () =>
  import(/* webpackChunkName: "faq" */ "@/views/manager/faq/index");
const ManagerFrequentlyAskedQuestionAdd = () =>
  import(/* webpackChunkName: "faq" */ "@/views/manager/faq/add");
const ManagerFrequentlyAskedQuestionEdit = () =>
  import(/* webpackChunkName: "faq" */ "@/views/manager/faq/edit");

const faq = [
  {
    path: "faq",
    name: "ManagerFrequentlyAskedQuestions",
    component: ManagerFrequentlyAskedQuestions,
    meta: {
      title: i18n.t("title.faq"),
    },
  },
  {
    path: "faq/add",
    name: "ManagerFrequentlyAskedQuestionAdd",
    component: ManagerFrequentlyAskedQuestionAdd,
    meta: {
      title: i18n.t("title.faq"),
    },
  },
  {
    path: "faq/:id/edit",
    name: "ManagerFrequentlyAskedQuestionEdit",
    component: ManagerFrequentlyAskedQuestionEdit,
    meta: {
      title: i18n.t("title.faq"),
    },
  },
];

export default faq;
