import i18n from "@/plugins/vue-i18n";

export default {
  namespaced: true,

  state: {
    transactions: [
      {
        code: "accountBalance",
        numericCode: 0,
      },
      {
        code: "payPal",
        numericCode: 1,
      },
      {
        code: "wireTransfer",
        numericCode: 5,
      },
      {
        code: "stripe",
        numericCode: 10,
      },
      {
        code: "usdtErc20",
        numericCode: 16,
      },
      {
        code: "usdtTrc20",
        numericCode: 17,
      },
    ],
    methods: [
      {
        code: "wireTransfer",
        numericCode: 5,
      },
      {
        code: "stripe",
        numericCode: 10,
      },
      {
        code: "usdtErc20",
        numericCode: 16,
      },
      {
        code: "usdtTrc20",
        numericCode: 17,
      },

      {
        code: "payPal",
        numericCode: 1,
        disabled: true,
      },
      {
        code: "payoneer",
        numericCode: null,
        disabled: true,
      },
      {
        code: "capitalistUSDT",
        numericCode: 14,
        disabled: true,
      },
      // {
      //   code: "bitpay",
      //   numericCode: null,
      //   disabled: true,
      // },
      {
        code: "skrill",
        numericCode: null,
        disabled: true,
      },
    ],
  },

  getters: {
    methodsList(state) {
      return state.methods.map((method) => {
        const { code, numericCode, disabled } = method;

        return {
          code,
          numericCode,
          disabled,
          name: i18n.t(`page.payments.methods.${code}`),
        };
      });
    },
    transactionsList(state) {
      return state.transactions.map((method) => {
        const { code, numericCode } = method;

        return {
          code: numericCode,
          label: i18n.t(`page.payments.methods.${code}`),
        };
      });
    },
  },
};
