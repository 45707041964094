export default {
  namespaced: true,

  state: {
    statusColors: {
      pending: "jaffa-2",
      disapproved: "burnt-sienna",
      disabled: "mystic",
      canceled: "burnt-sienna",
      precompleted: "mystic",
      completed: "jungle-green",
    },
  },

  getters: {
    statusColorsList(state) {
      return state.statusColors;
    },
  },
};
