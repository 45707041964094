export const pricingModels = ["CPM", "CPC"];

export const distributions = ["pacer", "fast"];

export const browsers = [
  "Chrome",
  "Edge",
  "IE",
  "Safari",
  "Mozilla",
  "Opera",
  "UCBrowser",
  "Yandex",
  "Android",
];

export const hours = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23,
];

export const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

export const languages = [
  "ab",
  "aa",
  "af",
  "sq",
  "am",
  "ar",
  "an",
  "hy",
  "as",
  "ay",
  "az",
  "ba",
  "eu",
  "bn",
  "dz",
  "bh",
  "bi",
  "br",
  "bg",
  "my",
  "be",
  "km",
  "ca",
  "zh",
  "zh-Hans",
  "zh-Hant",
  "co",
  "hr",
  "cs",
  "da",
  "nl",
  "en",
  "eo",
  "et",
  "fo",
  "fa",
  "fj",
  "fi",
  "fr",
  "fy",
  "gl",
  "gd",
  "gv",
  "ka",
  "de",
  "el",
  "kl",
  "gn",
  "gu",
  "ht",
  "ha",
  "he",
  "iw",
  "hi",
  "hu",
  "is",
  "io",
  "id",
  "in",
  "ia",
  "ie",
  "iu",
  "ik",
  "ga",
  "it",
  "ja",
  "jv",
  "kn",
  "ks",
  "kk",
  "rw",
  "ky",
  "rn",
  "ko",
  "ku",
  "lo",
  "la",
  "lv",
  "li",
  "ln",
  "lt",
  "mk",
  "mg",
  "ms",
  "ml",
  "mt",
  "mi",
  "mr",
  "mo",
  "mn",
  "na",
  "ne",
  "no",
  "oc",
  "or",
  "om",
  "ps",
  "pl",
  "pt",
  "pa",
  "qu",
  "rm",
  "ro",
  "ru",
  "sm",
  "sg",
  "sa",
  "sr",
  "sh",
  "st",
  "tn",
  "sn",
  "ii",
  "sd",
  "si",
  "ss",
  "sk",
  "sl",
  "so",
  "es",
  "su",
  "sw",
  "sv",
  "tl",
  "tg",
  "ta",
  "tt",
  "te",
  "th",
  "bo",
  "ti",
  "to",
  "ts",
  "tr",
  "tk",
  "tw",
  "ug",
  "uk",
  "ur",
  "uz",
  "vi",
  "vo",
  "wa",
  "cy",
  "wo",
  "xh",
  "yi",
  "ji",
  "yo",
  "zu",
];

export const statuses = ["pending", "approved", "disapproved", "disabled"];

export const macros = [
  "SUB_ID",
  "SITE_APP_ID",
  "SITE_DOMAIN_APP_NAME",
  "SITE_NAME",
  "SITE_APP_URL",
  "APP_BUNDLE",
  "PUBLISHER_ID",
  "SOURCE_ID",
  "USER_ID",
  "CID",
  "CREATIVE_ID",
  "IAB_CAT",
  "WIDTH",
  "HEIGHT",
  "GEO_LAT",
  "GEO_LON",
  "COUNTRY",
  "CITY",
  "IFA",
  "IP",
  "USER_AGENT",
  "CARRIER",
  "OS",
  "OSV",
  "BROWSER",
  "BROWSER_VER",
  "DATE_TIME",
  "PAGE_URL",
  "HASH",
  "CONNECTIONTYPE",
];

export const optimizationActions = ["ctr", "click", "winrate", "impressions", "dspPrice"];

export const mimeTypes = [
  "video/mpeg",
  "video/mp4",
  "video/quicktime",
  "video/x-flv",
  "video/x-ms-wmv",
  "video/webm",
];

export const playerSizes = ["small", "medium", "large", "x-large", "all"];
