import ApiService from "@/services/api.service";
import QS from "qs";
import { ISettings } from "@/models/common";
import { tCountries, tCities, tRegions, Cities, Countries, Regions } from "@/models/geo";

const api = new ApiService();

class GeoApi {
  public async countries(): Promise<Countries> {
    return await api.get(`/geo/countries`, { tModel: tCountries });
  }

  public async regions(
    query: any,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tRegions }
  ): Promise<Regions> {
    const queryString = QS.stringify(query);

    return await api.get(`/geo/regions?${queryString}`, settings);
  }

  public async cities(
    query: any,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tCities }
  ): Promise<Cities> {
    const queryString = QS.stringify(query);

    return await api.get(`/geo/cities?${queryString}`, settings);
  }
}

export default new GeoApi();
