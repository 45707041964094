import i18n from "@/plugins/vue-i18n";

const TrafficCalculator = () =>
  import(/* webpackChunkName: "trafficCalculator" */ "@/views/advert/traffic-calculator");

const trafficCalculator = [
  {
    path: "/traffic-calculator",
    name: "TrafficCalculator",
    component: TrafficCalculator,
    meta: {
      title: i18n.t("title.trafficCalculator"),
    },
  },
];

export default trafficCalculator;
