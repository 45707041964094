import i18n from "@/plugins/vue-i18n";

const PaymentsAdmin = () => import(/* webpackChunkName: "payments" */ "@/views/admin/payments");
const PaymentsCompensation = () =>
  import(/* webpackChunkName: "payments" */ "@/views/admin/payments/compensation");

const payments = [
  {
    path: "payments/:type?",
    name: "PaymentsAdmin",
    component: PaymentsAdmin,
    meta: {
      title: i18n.t("title.payments"),
    },
  },
  {
    path: "payments/compensation/:id?",
    name: "PaymentsCompensation",
    component: PaymentsCompensation,
    meta: {
      title: i18n.t("title.compensation"),
    },
  },
];

export default payments;
