import { tMeta } from "@/models/common";
import { IUser, tUser } from "@/models/user";
import { IMediaAsset, tMediaAsset } from "@/models/media-asset";
import * as t from "io-ts";

export interface ICreative {
  id: number;
  name: string;
  creativeType: string;
  size: string;
  weight: string;
  tmt: {
    status: string;
  };
  media?: IMediaAsset;
  relation?: {
    banner?: {
      clickUrl?: string;
    };
    push?: {
      smallImageId?: string;
      smallImage?: IMediaAsset;
      largeImageId?: string;
      largeImage?: IMediaAsset;
      assetTitle?: string;
      assetDataDesc?: string;
      clickUrl?: string;
    };
    pop?: {
      clickUrl?: string;
    };
    native?: {
      largeImageId?: string;
      largeImage?: IMediaAsset;
      clickUrl?: string;
    };
    interstitial?: {
      clickUrl?: string;
    };
  };
  bidPrice?: number;
  user: IUser;
  isActive: boolean;
  userId: number;
  url: string;
}

export interface ICampaignCreativesParams {
  id: number;
  ids: Array<number>;
}

export interface ICheckTargetUrl {
  clickUrl: string;
}

export interface ICheckIframe {
  admBody: string;
}

const tBanner = t.strict({
  apiFramework: t.union([t.number, t.null]),
  admBody: t.string,
  admType: t.string,
  subType: t.string,
  clickUrl: t.union([t.string, t.null]),
  width: t.union([t.number, t.null]),
  height: t.union([t.number, t.null]),
  childrenCreative: t.union([t.array(t.unknown), t.null]), // unknown field data
  statusChildrenCreative: t.array(t.unknown), // unknown field data
  main: t.union([t.number, t.null]),
});

const tNative = t.strict({
  smallImageId: t.union([t.number, t.null]),
  // TODO: smallImage as media asset
  smallImage: t.union([
    t.strict({
      id: t.number,
      originName: t.string,
      url: t.string,
      width: t.number,
      height: t.number,
    }),
    t.strict({
      id: t.number,
    }),
    t.null,
  ]),
  largeImageId: t.number,
  // TODO: largeImage as media asset
  largeImage: t.union([
    t.strict({
      id: t.number,
      originName: t.string,
      url: t.string,
      width: t.number,
      height: t.number,
    }),
    t.strict({
      id: t.number,
    }),
    t.null,
  ]),
  assetDataDesc: t.union([t.string, t.null]),
  assetTitle: t.union([t.string, t.null]),
  assetDataSponsored: t.union([t.string, t.null]),
  assetCtaText: t.union([t.string, t.null]),
  clickUrl: t.string,
  displayUrl: t.union([t.string, t.null]),
});

const tInterstitial = t.strict({
  apiFramework: t.union([t.number, t.null]),
  admBody: t.string,
  admType: t.string,
  subType: t.string,
  clickUrl: t.union([t.string, t.null]),
  width: t.union([t.number, t.null]),
  height: t.union([t.number, t.null]),
  childrenCreative: t.union([t.array(t.unknown), t.null]), // unknown field data
  statusChildrenCreative: t.array(t.unknown), // unknown field data
  main: t.union([t.number, t.null]),
});

const tPush = t.strict({
  smallImageId: t.number,
  smallImage: tMediaAsset,
  largeImageId: t.number,
  largeImage: tMediaAsset,
  assetTitle: t.string,
  assetDataDesc: t.union([t.string, t.null]),
  assetDataSponsored: t.null,
  clickUrl: t.string,
});

const tPop = t.strict({
  assetTitle: t.null,
  assetDataDesc: t.null,
  assetDataSponsored: t.null,
  clickUrl: t.string,
});

const tVideo = t.strict({
  sizes: t.string,
  admBody: t.string,
  admType: t.string,
  vastAdTagURI: t.union([t.string, t.null]),
  mimeType: t.array(t.string), // TODO: why array
  width: t.number,
  height: t.number,
  duration: t.number,
  clickUrl: t.union([t.string, t.null]),
  skipVastValidation: t.boolean,
});

const tTmt = t.strict({
  status: t.string,
  result: t.string,
  data: t.strict({
    tagName: t.null,
    hitType: t.null,
    urgency: t.null,
    confidence: t.null,
    comment: t.null,
    incidentUrl: t.array(t.unknown), // unknown data
  }),
});

export const tCreative = t.strict({
  id: t.number,
  userId: t.number,
  isActive: t.union([t.number, t.null]), // TODO: fix type (required)
  approved: t.union([t.string, t.null]), // TODO: fix type (required)
  skipIsSecureCheck: t.boolean,
  creativeType: t.string,
  name: t.string,
  bidPrice: t.union([t.string, t.number, t.null]), // TODO: fix type (number)
  autoresize: t.union([t.number, t.null]),
  bidPriceCancellation: t.boolean,
  size: t.string,
  tmt: t.union([tTmt, t.undefined]),
  adaptiveCpm: t.union([t.boolean, t.null]),
  weight: t.string,
  folderId: t.union([t.number, t.null]),
  createdAt: t.string,
  updatedAt: t.string,
  user: t.union([tUser, t.undefined]),
  pendingAt: t.union([t.string, t.null]),
  deletedAt: t.union([t.string, t.null]),
  media: t.union([tMediaAsset, t.null]),
  dspSpend: t.union([t.number, t.null, t.undefined]),
  ctr: t.union([t.number, t.null, t.undefined]),
  clicks: t.union([t.number, t.null, t.undefined]),
  impressions: t.union([t.number, t.null, t.undefined]),
  wins: t.union([t.number, t.null, t.undefined]),
  bids: t.union([t.number, t.null, t.undefined]),
  winRate: t.union([t.number, t.null, t.undefined]),
  relation: t.strict({
    banner: t.union([tBanner, t.undefined]),
    native: t.union([tNative, t.undefined]),
    interstitial: t.union([tInterstitial, t.undefined]),
    push: t.union([tPush, t.undefined]),
    pop: t.union([tPop, t.undefined]),
    video: t.union([tVideo, t.undefined]),
  }),
  campaigns: t.union([t.array(t.unknown), t.undefined]), // TODO: describe Campaign
});

export const tCreatives = t.strict({
  data: t.array(tCreative),
  meta: tMeta,
  campaign: t.union([t.unknown, t.undefined]), // TODO: describe Campaign
});

export type Creative = t.TypeOf<typeof tCreative>;
export type Creatives = t.TypeOf<typeof tCreatives>;

export interface CreativeCreateForm {
  name: string;
  creativeType: string;
  mediaId: number;
  banner?: {
    clickUrl: string;
    admBody?: string;
    admType?: string;
  };
  native?: {
    largeImageId: number;
    clickUrl: string;
    assetTitle: string;
    assetDataDesc: string;
    assetCtaText: string;
    displayUrl: string;
    assetDataSponsored: string;
  };
  interstitial?: {
    clickUrl: string;
  };
  push?: {
    smallImageId: number;
    largeImageId: number;
    assetTitle: string;
    assetDataDesc: string | null;
    clickUrl: string;
  };
  pop?: {
    clickUrl: string;
  };
  video?: {
    sizes: string;
    mimeType: Array<string>;
    clickUrl: string;
  };
  autoresizeArr: [];
  bidPrice: string;
  // TODO: remove unused categories
  categories: object;
  // TODO: remove unused subCategories
  subCategories: object;
  bidPriceCancellation?: true;
  //TODO: canceling min value validation
}

export interface CreativeUpdateForm {
  name?: string;
  creativeType?: string;
  mediaId?: number;
  banner?: {
    clickUrl: string;
    admBody?: string;
  };
  native?: {
    largeImageId: number;
    clickUrl: string;
    assetTitle: string;
    assetDataDesc: string;
    assetCtaText: string;
    displayUrl: string;
    assetDataSponsored: string;
  };
  interstitial?: {
    clickUrl: string;
  };
  push?: {
    smallImageId: number;
    largeImageId: number;
    assetTitle: string;
    assetDataDesc: string | null;
    clickUrl: string;
  };
  pop?: {
    clickUrl: string;
  };
  video?: {
    sizes: string;
    mimeType: Array<string>;
    clickUrl: string;
  };
  autoresizeArr?: [];
  bidPrice?: string;
  // TODO: remove unused categories
  categories?: object;
  // TODO: remove unused subCategories
  subCategories?: object;
  isActive?: boolean;
  approved?: string;
  bidPriceCancellation?: true;
  //TODO: canceling min value validation
}
