import NotificationApi from "@/api/notifications";
import DateService, {
  BACKEND_DATETIME_FORMAT,
  FRONTEND_NOTIFICATION_DATETIME_FORMAT,
} from "@/services/date.service";

export default {
  namespaced: true,

  state: {
    newNotifications: [],
  },

  mutations: {
    SET_NEW_NOTIFICATIONS(state, notifications) {
      state.newNotifications = notifications.map(({ data, createdAt, readedAt, id }) => {
        return {
          id,
          message: data.message,
          date: DateService.convertDate(
            createdAt,
            BACKEND_DATETIME_FORMAT,
            FRONTEND_NOTIFICATION_DATETIME_FORMAT
          ),
          isUnread: !readedAt,
        };
      });
    },
  },

  actions: {
    async getNewNotifications(context) {
      const { data } = await NotificationApi.new();

      context.commit("SET_NEW_NOTIFICATIONS", data);
    },

    async readAllNotifications() {
      await NotificationApi.readAll();
    },
  },

  getters: {
    newNotificationsList(state) {
      return state.newNotifications;
    },
  },
};
