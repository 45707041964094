<template>
  <div class="re-captcha" :class="wrapperClasses">
    <vue-recaptcha
      ref="recaptcha"
      :sitekey="reCaptchaSiteKey"
      @verify="onVerify"
      @expired="onExpired"
    />

    <ErrorTooltip v-if="error" :error="error" />
  </div>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
import { IconSvg } from "@/components";
import ErrorTooltip from "/src/components/ErrorTooltip";

export default {
  name: "ReCaptcha",

  components: { VueRecaptcha, IconSvg, ErrorTooltip },

  props: {
    value: {
      type: String,
      default: "",
    },

    error: {
      type: String,
      default: "",
    },
  },

  computed: {
    reCaptchaSiteKey() {
      return process.env.VUE_APP_RECAPTCHA_SITEKEY;
    },

    wrapperClasses() {
      return {
        "re-captcha-error": this.error,
      };
    },

    token: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    onVerify(token) {
      this.token = token;
    },

    onExpired() {
      this.token = "";
      this.$refs.recaptcha.reset();
    },
  },
};
</script>

<style lang="postcss" scoped>
.re-captcha {
  @apply flex flex-col;
  @apply relative;
}
</style>
