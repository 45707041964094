export default {
  namespaced: true,

  state: {
    windowWidth: 0,
    breakpoint: "",
    mobileDevices: ["xs", "sm"],
    isFullscreen: false,
  },

  getters: {
    isMobileDevice(state) {
      const { breakpoint, mobileDevices } = state;

      return mobileDevices.includes(breakpoint);
    },

    inputSize(state, getters) {
      const { isMobileDevice } = getters;

      return isMobileDevice ? "md" : "lg";
    },

    isFullscreenContainerPage(state) {
      return state.isFullscreen;
    },
  },

  mutations: {
    SET_PARAMS(state, { windowWidth, breakpoint }) {
      state.windowWidth = windowWidth;
      state.breakpoint = breakpoint;
    },

    SET_FULLSCREEN(state, fullscreenStatus) {
      state.isFullscreen = fullscreenStatus;
    },
  },

  actions: {
    toggleFullscreenStatus(context, fullscreenStatus) {
      context.commit("SET_FULLSCREEN", fullscreenStatus);
    },
  },
};
