import i18n from "@/plugins/vue-i18n";

const FAQ = () => import(/* webpackChunkName: "faq" */ "@/views/advert/faq/index");

const faq = [
  {
    path: "/faq",
    name: "FAQ",
    component: FAQ,
    meta: {
      title: i18n.t("title.faq"),
    },
  },
];

export default faq;
