import ApiService from "@/services/api.service";
import axios, { AxiosResponse } from "axios";
import {
  CheckEmailParams,
  CheckEmailResponse,
  IRefreshAuthResponse,
  ISendToHubSpotParams,
  Login,
  LoginParams,
  ResetPassword,
  SignUpParams,
  tCheckEmail,
  tLogin,
} from "@/models/auth";
import { ICreateUserQuery, ISettings } from "@/models/common";
import { tUser, User } from "@/models/user";
import QS from "qs";

const api = new ApiService();

class AuthApi {
  public async login(
    params: LoginParams,
    settings: ISettings = { showErrors: true, tModel: tLogin }
  ): Promise<Login> {
    return await api.post("/users/login", params, settings);
  }

  public async resetPassword(
    params: ResetPassword,
    settings: ISettings = { withAxiosResponseOnSuccess: true }
  ): Promise<AxiosResponse> {
    return await api.post("/users/password/reset", params, settings);
  }

  public async signUp(
    query: ICreateUserQuery,
    params: SignUpParams,
    settings: ISettings = { withAxiosResponseOnSuccess: true, showErrors: true }
  ): Promise<AxiosResponse> {
    const queryString = QS.stringify(query);

    return await api.post(`/users/signup?${queryString}`, params, settings);
  }

  public async refresh(): Promise<IRefreshAuthResponse> {
    return await api.post("/users/token/refresh");
  }

  public async profile(): Promise<User> {
    return await api.get("/users/profile", { tModel: tUser });
  }

  public async checkEmail(params: CheckEmailParams): Promise<CheckEmailResponse> {
    return await api.post("/users/check-email", params, {
      withLoader: false,
      tModel: tCheckEmail,
    });
  }

  public async logout(
    settings: ISettings = { withAxiosResponseOnSuccess: true }
  ): Promise<AxiosResponse> {
    return await api.post("/logout", null, settings);
  }

  // TODO: remove
  public async sendToHubSpot(params: ISendToHubSpotParams): Promise<AxiosResponse> {
    const axiosInstance = axios.create();

    axiosInstance.defaults.headers.common = {};
    const url =
      "https://api.hsforms.com/submissions/v3/integration/submit/2993981/0a7aaa64-79aa-4e56-95e8-3b903a7ec41c";

    return await axiosInstance.post(url, params);
  }
}

export default new AuthApi();
