import * as t from "io-ts";

export interface IMediaAsset {
  id: number;
  originName: string;
  url: string;
  width: number;
  height: number;
}

export const tMediaAsset = t.strict({
  id: t.number,
  userId: t.number,
  mediaType: t.string,
  originName: t.string,
  name: t.string,
  extension: t.string,
  url: t.string,
  width: t.number,
  height: t.number,
  weight: t.number,
  modifiedUrl: t.string,
});

export const tImage = t.array(
  t.strict({
    createdAt: t.string,
    extension: t.string,
    height: t.number,
    id: t.number,
    mediaType: t.string,
    modifiedUrl: t.string,
    name: t.string,
    originName: t.string,
    updatedAt: t.string,
    url: t.string,
    userId: t.number,
    weight: t.number,
    width: t.number,
  })
);

export const tVideo = t.array(
  t.strict({
    bitrate: t.number,
    createdAt: t.string,
    duration: t.number,
    durationTime: t.string,
    extension: t.string,
    height: t.number,
    id: t.number,
    mediaType: t.string,
    mimeType: t.string,
    modifiedUrl: t.string,
    name: t.string,
    originName: t.string,
    playtime: t.string,
    updatedAt: t.string,
    url: t.string,
    userId: t.number,
    weight: t.number,
    width: t.number,
  })
);

export type Image = t.TypeOf<typeof tImage>;
export type Video = t.TypeOf<typeof tVideo>;
export type MediaAsset = t.TypeOf<typeof tMediaAsset>;
