import dashboard from "./dashboard";
import campaigns from "./campaigns";
import creatives from "./creatives";
import filters from "./filters";
import statistics from "./statistics";
import payments from "./payments";
import settings from "./settings";
import trafficCalculator from "./traffic-calculator";
import audience from "./audience";
import faq from "./faq";

const AdvertLayout = () => import(/* webpackChunkName: "advert" */ "@/layouts/advert");

const advertLayout = [
  {
    path: "/",
    name: "AdvertLayout",
    component: AdvertLayout,
    redirect: { name: "Dashboard" },
    children: [
      ...dashboard,
      ...campaigns,
      ...creatives,
      ...filters,
      ...statistics,
      ...payments,
      ...settings,
      ...trafficCalculator,
      ...audience,
      ...faq,
    ],
  },
];

export default advertLayout;
