import i18n from "@/plugins/vue-i18n";

const PasswordChanged = () =>
  import(/* webpackChunkName: "thanks" */ "@/views/thanks/PasswordChanged");

const passwordChanged = [
  {
    path: "/password-changed",
    name: "PasswordChanged",
    component: PasswordChanged,
    meta: {
      title: i18n.t("title.yourPasswordHasBeenSuccessfullyChanged"),
    },
  },
];

export default passwordChanged;
