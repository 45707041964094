import { tMeta } from "@/models/common";
import * as t from "io-ts";

export const tCountry = t.strict({
  country_id: t.number,
  country_name: t.string,
  iso3: t.string,
  min_CPM: t.number,
  recommended_CPM: t.number,
});

export const tCity = t.array(
  t.strict({
    city_name: t.string,
    country_iso3: t.string,
    geoname_id: t.number,
    state: t.string,
    state_iso2: t.string,
  })
);

export const tRegion = t.array(
  t.strict({
    country_iso3: t.string,
    state_iso2: t.string,
    state: t.string,
  })
);

export const tCountries = t.strict({
  data: t.array(tCountry),
  meta: tMeta,
});

export const tCities = t.strict({
  data: tCity,
  meta: tMeta,
});

export const tRegions = t.strict({
  data: tRegion,
  meta: tMeta,
});

export const tCheckPasswordParams = t.strict({
  password: t.string,
});

export type Countries = t.TypeOf<typeof tCountries>;
export type Cities = t.TypeOf<typeof tCities>;
export type Regions = t.TypeOf<typeof tRegions>;
