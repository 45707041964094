import { AxiosResponse } from "axios";
import ApiService from "@/services/api.service";
import {
  tCreative,
  Creative,
  Creatives,
  tCreatives,
  CreativeUpdateForm,
  CreativeCreateForm,
} from "@/models/creative";
import QS from "qs";
import { ListQuery } from "@/services/query-builder";
import { ISettings } from "@/models/common";

const api = new ApiService();

class CreativeAdminApi {
  public async get(query: ListQuery): Promise<Creatives> {
    const queryString = QS.stringify(query);

    return await api.get(`/admin/creatives?${queryString}`, { tModel: tCreatives });
  }

  public async find(id: number): Promise<Creative> {
    return await api.get(`/admin/creatives/${id}`, {
      resultKeyOnSuccess: "data",
      tModel: tCreative,
    });
  }

  public async getActive(query: ListQuery): Promise<Creatives> {
    const queryString = QS.stringify(query);

    return await api.get(`/admin/creatives/active/index?${queryString}`, { tModel: tCreatives });
  }

  public async create(params: CreativeCreateForm): Promise<Creative> {
    return await api.post("/admin/creatives", params, {
      showErrors: true,
      resultKeyOnSuccess: "data",
      tModel: tCreative,
    });
  }

  // TODO: describe params
  public async update(
    id: number | string,
    params: CreativeUpdateForm,
    settings: ISettings = { showErrors: true, resultKeyOnSuccess: "data" }
  ): Promise<Creative> {
    settings.tModel = tCreative;

    return await api.put(`/admin/creatives/${id}`, params, settings);
  }

  public async delete(id: number | string): Promise<AxiosResponse> {
    return await api.delete(`/admin/creatives/${id}`, {
      withLoader: false,
      withAxiosResponseOnSuccess: true,
    });
  }

  public async getActiveCount(): Promise<number> {
    return await api.get("/admin/creatives/active/count");
  }
}

export default new CreativeAdminApi();
