<template>
  <perfect-scrollbar class="info-block-wrapper">
    <div v-for="(item, index) in items" :key="index" class="item">
      <template v-if="isExistSlot(`block-${item.slot}`, slots)">
        <slot :item="item" :name="`block-${item.slot}`" />
      </template>

      <template v-else>
        <div class="label">{{ item.label }}</div>
        <div class="description">{{ item.value }}</div>
      </template>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { isExistSlot } from "@/plugins/helpers";

export default {
  name: "InfoBlocks",

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      slots: this.$scopedSlots,
    };
  },
  methods: { isExistSlot },
};
</script>

<style scoped lang="postcss">
.info-block-wrapper {
  @apply flex flex-row;
  @apply lg:space-x-2.5;
  @apply flex-wrap;
  @apply lg:flex-nowrap;

  .item {
    @apply h-16 rounded-[0.938rem] bg-white p-[0.813rem];
    @apply flex flex-col items-center justify-between;
    @apply overflow-hidden;
    @apply w-full sm:w-[49%] lg:w-full;
    @apply mb-2 lg:mb-0;
    @apply odd:mr-0 sm:odd:mr-2 lg:odd:mr-0;

    .label {
      @apply flex items-center whitespace-nowrap text-xs text-pale-sky;
    }

    .description {
      @apply whitespace-nowrap text-base text-ebony-clay;
    }
  }
}
</style>
