import Vue from "vue";

import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

Vue.use(VueTippy, {
  defaultProps: {
    arrow: true,
    theme: "light",
    allowHTML: true,
  },
});
