import AuthApi from "@/api/auth";
import { formatNumber } from "@/mixins/global.mixin";
import UserApi from "@/api/users";
import NotifyService from "@/services/notify.service";
import QueryBuilder from "@/services/query-builder";
import ApiService from "@/services/api.service";
import JwtService from "@/services/jwt.service";

export default {
  namespaced: true,

  state: {
    user: {},
    sudoUser: {},

    users: {
      data: [],
      pagination: {},
    },
  },

  getters: {
    isExistProfile(state) {
      return !!Object.keys(state.user).length;
    },

    isExistSudoProfile(state) {
      return !!Object.keys(state.sudoUser).length;
    },

    isAdvert(state) {
      return state.user.role === "advert";
    },

    isAdmin(state) {
      return state.user.role === "admin";
    },

    isManager(state) {
      return state.user.role === "manager";
    },

    userProfile(state, getters) {
      return getters.isExistSudoProfile ? state.sudoUser : state.user;
    },

    userFullName(state, getters) {
      const { firstName, lastName } = getters.userProfile;

      return `${firstName} ${lastName || ""}`.trim();
    },

    userEmail(state, getters) {
      const { email } = getters.userProfile;

      return email;
    },

    userBalance(state, getters) {
      const { balance } = getters.userProfile;

      return formatNumber(balance, { style: "currency" });
    },

    isAllowableBalance(state, getters) {
      const { balance } = getters.userProfile;

      return balance > 5;
    },

    userId(state, getters) {
      return getters.userProfile.id;
    },

    getUsers(state) {
      return state.users;
    },
  },

  mutations: {
    SET_PROFILE(state, user = {}) {
      state.user = user;
    },

    SET_SUDO_PROFILE(state, sudoUser = {}) {
      state.sudoUser = sudoUser.id !== state.user.id ? sudoUser : {};
      JwtService.setSudoUser(JSON.stringify(state.sudoUser));
    },

    SET_USERS(state, { data, meta }) {
      if (data) state.users.data = data;
      if (meta) state.users.pagination = meta.pagination;
    },
  },

  actions: {
    async getProfile(context) {
      const response = await AuthApi.profile();

      await context.commit("SET_PROFILE", response);

      const sudoUserProfileJson = JwtService.getSudoUser();

      if (sudoUserProfileJson) {
        const sudoUserProfile = JSON.parse(sudoUserProfileJson);

        await context.commit("SET_SUDO_PROFILE", sudoUserProfile);

        ApiService.setHeaders();
      }
    },

    async getUsers(context, filters = {}) {
      const { page = 1, search = "", perPage } = filters;

      const builder = new QueryBuilder();

      builder.where("id", "like", `%${search}%`, "search");
      builder.orWhere("firstName", "like", `%${search}%`, "search");
      builder.orWhere("lastName", "like", `%${search}%`, "search");
      builder.orWhere("email", "like", `%${search}%`, "search");

      builder.whereIn("role", ["advert"]);

      const query = {
        arrayQuery: builder.query,
        page,
        perPage,
      };

      const { data, meta } = await UserApi.get(query);

      context.commit("SET_USERS", { data, meta });
    },

    async deleteUser(context, params = {}) {
      const { id, wipePersonalInfo = false } = params;
      const query = { wipePersonalInfo };

      await UserApi.delete(id, query).then(() => {
        NotifyService.success("userDeletedSuccessfully");
      });
    },
  },
};
