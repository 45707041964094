import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import commonModules from "@/store/modules";

export default new Vuex.Store({
  modules: {
    ...commonModules,
  },
});
