<template>
  <div v-show="isActive">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tab",

  props: {
    label: {
      type: [String],
      required: true,
    },

    active: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    extension: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isActive: false,
    };
  },

  watch: {
    active: {
      handler: "onChangeActive",
      immediate: true,
    },
  },

  methods: {
    onChangeActive(value) {
      this.isActive = value;
    },
  },
};
</script>
