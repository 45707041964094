import i18n from "@/plugins/vue-i18n";

const Statistics = () =>
  import(/* webpackChunkName: "statistics" */ "@/views/advert/statistics/index");

const statistics = [
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    meta: {
      title: i18n.t("title.statistics"),
    },
  },
];

export default statistics;
