import Vue, { createApp } from "vue";
import App from "./App.vue";
import "/src/assets/css/index.pcss";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./plugins/vue-cookies";
import "./plugins/vue-tailwind";
import "./plugins/vue-click-outside";
import "./plugins/vue-notification";
import "./plugins/vue-progressbar";
import "./plugins/fancyapps";
import "./plugins/highcharts-vue";
import "./mixins/global.mixin";
import i18n from "@/plugins/vue-i18n";
import ApiService from "./services/api.service";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

import "@/plugins";
import "@/components";

Sentry.init({
  Vue,
  dsn: "https://c55c34871a0848dfbe8c783b87c9f7bf@inhouse-sentry.smartyads.com//36",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["dsp.afront.io", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  enabled: process.env.NODE_ENV === "production",
});

ApiService.init();

Vue.config.productionTip = false;

Vue.prototype.$store = store;

const app = createApp({ i18n, store, ...App });

app.use(router);

app.mount("#app");
