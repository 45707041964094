import i18n from "@/plugins/vue-i18n";
import VueI18n from "vue-i18n";
import NumberFormatOptions = VueI18n.NumberFormatOptions;
import store from "@/store";
import { IUser } from "@/models/user";
import ApiService from "@/services/api.service";

import Vue from "vue";
import debounce from "lodash/debounce";

Vue.mixin({
  mounted() {
    Vue.nextTick(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.fireVueReadyEvent();
    });
  },

  methods: {
    fireVueReadyEvent: debounce(async function () {
      const event = new CustomEvent("vueRendered");

      document.dispatchEvent(event);
    }, 1000),
  },
});

export function formatNumber(value = 0, options: NumberFormatOptions = {}): string {
  const {
    minimumFractionDigits = 0,
    maximumFractionDigits = 2,
    style = "decimal",
    currency = "USD",
  } = options;

  return Intl.NumberFormat(i18n.locale, {
    minimumFractionDigits,
    maximumFractionDigits,
    style,
    currency,
  })
    .format(value)
    .replace(/^(\D+)/, " $1 ")
    .replace("%", " % ")
    .trim();
}

export function getRandomId(idLength = 15): string {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const charactersLength = characters.length;
  let id = "";

  while (id.length < idLength) {
    id += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return id;
}

export function resetAppCache() {
  const promises: Array<Promise<boolean>> = [];

  caches.keys().then(function (names) {
    for (const name of names) {
      promises.push(caches.delete(name));
    }
  });

  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      for (const registration of registrations) {
        promises.push(registration.unregister());
      }
    });
  }

  Promise.all(promises).then(() => {
    window.location.reload();
  });
}

export function redirectProfile(profile: IUser) {
  store.commit("user/SET_SUDO_PROFILE", profile);

  ApiService.setHeaders();

  store.dispatch("app/refreshAppKey");
}
