import i18n from "@/plugins/vue-i18n";
import {
  types,
  connectionTypes,
  deviceBrands,
  iabCategories,
  deviceTypes,
  mimeTypes,
  trafficTypes,
  operationSystems,
  timezones,
} from "@/data/common";

import { camelCase } from "lodash";
import GeoApi from "@/api/geo";

export default {
  namespaced: true,

  state: {
    timezones,
    types,
    trafficTypes,
    connectionTypes,
    operationSystems,
    deviceBrands,
    iabCategories,
    deviceTypes,
    mimeTypes: mimeTypes,

    geoCountries: [],
    weekends: ["saturday", "sunday"],
  },

  getters: {
    timezoneOptions(state) {
      return state.timezones.map((code) => {
        const labelCode = code.toString().split(".").join("_");

        return {
          code,
          label: i18n.t(`timezone.${labelCode}`),
        };
      });
    },

    typeOptions(state) {
      return state.types.map((code) => ({
        code,
        label: i18n.t(`campaign.type.${code}`),
      }));
    },

    trafficTypeOptions(state) {
      return state.trafficTypes.map(({ code }) => ({
        code,
        label: i18n.t(`trafficType.${code}`),
      }));
    },

    connectionTypeOptions(state) {
      return state.connectionTypes.map((code) => ({
        code,
        label: i18n.t(`campaign.connectionType.${camelCase(code)}`),
      }));
    },

    operationSystemOptions(state) {
      return state.operationSystems.map(({ name, versions }) => {
        return {
          code: name,
          label: name,
          versions: versions.map((version) => ({
            code: `${name} ${version}`,
            label: `${name} ${version}`,
            group: name,
          })),
        };
      });
    },

    deviceBrandOptions(state) {
      return state.deviceBrands.map((code) => ({
        code,
        label: i18n.t(`campaign.deviceBrand.${camelCase(code)}`),
      }));
    },

    iabCategoriesOptions(state) {
      return state.iabCategories.map((code) => ({
        code,
        label: i18n.t(`campaign.iabCategories.${code}`),
      }));
    },

    deviceTypeOptions(state) {
      return state.deviceTypes.map((code) => ({
        code,
        label: i18n.t(`campaign.deviceType.${code}`),
      }));
    },

    geoCountriesOptions(state) {
      return state.geoCountries;
    },
  },

  mutations: {
    SET_GEO_COUNTRIES(state, geoCountries) {
      state.geoCountries = geoCountries.map((geoCountry) => {
        const {
          iso3: code,
          country_name: label,
          min_CPM: minCPM,
          recommended_CPM: recommendedCPM,
        } = geoCountry;

        return {
          code,
          label,
          minCPM,
          recommendedCPM,
        };
      });
    },
  },

  actions: {
    async getGeoCountries(context) {
      if (!context.state.geoCountries.length) {
        const response = await GeoApi.countries();

        context.commit("SET_GEO_COUNTRIES", response.data);
      }
    },

    closeMessageAboutTraffic({ commit }) {
      commit("CLOSE_MESSAGE_ABOUT_TRAFFIC");
    },
  },
};
