import { tMeta } from "@/models/common";
import * as t from "io-ts";

export const tNotification = t.strict({
  createdAt: t.string,
  data: t.strict({
    color: t.string,
    message: t.string,
    route: t.string,
    route_params: t.array(t.string),
  }),
  id: t.string,
  readedAt: t.union([t.string, t.null]),
  type: t.string,
  updatedAt: t.string,
});

export const tNotifications = t.strict({
  data: t.array(tNotification),
  meta: tMeta,
});

export type Notifications = t.TypeOf<typeof tNotifications>;
export type Notification = t.TypeOf<typeof tNotification>;
