<template>
  <div class="tabs-wrapper" :class="tabsClasses">
    <div ref="header" class="header">
      <div
        v-for="(tab, index) in tabs"
        :key="index"
        v-tippy="tippyConfig(tab)"
        v-touch="selectTabTouch(index)"
        :content="tab.label"
        class="title"
        :class="getTitleClass(tab)"
        :data-cy="getKebabCaseDataCy(tab.label)"
        @click="selectTab(index)"
      >
        <div ref="label" class="label-container">
          <slot name="label" :tab="tab">
            {{ tab.label }}
          </slot>
        </div>
      </div>
    </div>

    <div class="tab-content">
      <slot />
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from "perfect-scrollbar";
import { kebabCase } from "lodash";

export default {
  name: "Tabs",

  props: {
    variant: {
      type: String,
      default: "primary",
    },
  },

  data() {
    return {
      selectedIndex: null,
      tabs: [],
    };
  },

  computed: {
    tabsClasses() {
      const variant = `tabs-${this.variant}`;

      return [variant];
    },
  },

  mounted() {
    this.tabs = this.$children.filter(({ $ }) => $.type.name === "Tab");

    this.setActiveTab();
    new PerfectScrollbar(this.$refs.header);
  },

  updated() {
    this.$refs.label.map((el, index) => {
      if (el.firstChild.offsetWidth < el.firstChild.scrollWidth) {
        this.tabs[index].showDescription = true;
      }
    });
  },

  methods: {
    setActiveTab() {
      this.tabs.find(({ disabled }, index) => {
        if (!disabled) {
          this.selectedIndex = index;

          return true;
        }
      });

      this.tabs.find(({ isActive, disabled }, index) => {
        const isActiveTab = isActive && !disabled;

        if (isActiveTab) {
          this.selectedIndex = index;

          return true;
        }
      });

      this.selectTab(this.selectedIndex);
    },

    getKebabCaseDataCy(label) {
      return `tab-${kebabCase(label)}-button`;
    },

    selectTab(index) {
      const tab = this.tabs[index];

      if (tab) {
        // TODO: fix in front/src/views/admin/dashboard/index.vue and remove here
        const activeTabName = tab.$children[0]?.$options.propsData.ref;

        if (!tab?.disabled) {
          this.$emit("setActiveTab", activeTabName);

          this.selectedIndex = index;

          this.tabs.forEach((tab, position) => {
            tab.isActive = position === index;
          });
        }
      }
    },

    selectTabTouch(index) {
      return () => {
        this.selectTab(index);
      };
    },

    getTitleClass(tab) {
      return {
        "title-active": tab.isActive,
        "title-disabled": tab.disabled,
      };
    },

    tippyConfig(tab) {
      return { onShow: () => !!tab.showDescription };
    },
  },
};
</script>

<style lang="postcss" scoped>
.tabs-wrapper {
  @apply rounded;

  .header {
    @apply flex;

    .title {
      @apply whitespace-nowrap leading-none;
      @apply grow p-3;
      @apply text-center text-xs;
      @apply cursor-pointer;
      @apply rounded-[0.9375rem];
      @apply border border-transparent;
      @apply last:!mr-0;

      @apply w-full;
      @apply overflow-hidden;

      &-active {
      }

      &-disabled {
        @apply cursor-default;
      }
    }
  }

  &.tabs-primary {
    .header {
      @apply mb-5 w-full;
      @apply flex-wrap space-x-2.5 space-y-2;
      @apply lg:space-y-0;
      @apply lg:flex-nowrap;

      .title {
        @apply w-[45%] text-pale-sky;
        @apply bg-white;

        &-active {
          @apply border-jaffa-1;
        }

        &-disabled {
          @apply text-gray-500;
        }
      }
    }
  }

  &.tabs-secondary {
    @apply bg-white p-2.5;

    .header {
      @apply mb-7 p-2.5;

      .title {
        @apply text-pale-sky;
        @apply bg-athens-gray-1;
        @apply mr-[0.3125rem];

        &-active {
          @apply border-jaffa-1;
        }

        &-disabled {
          @apply text-gray-500;
        }
      }
    }
  }

  &.tabs-tertiary {
    .header {
      .title {
        @apply whitespace-nowrap border-0;
        @apply rounded-none;
        @apply border-b border-mystic;
        @apply max-w-[10rem];

        &-active {
          @apply border-b-2 border-jaffa-1;

          .label-container {
            @apply text-lg font-bold;
          }
        }

        .label-container {
          @apply text-base text-pale-sky;
        }
      }
    }
  }
}
</style>
