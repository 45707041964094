import ApiService from "@/services/api.service";
import { MediaAsset, tMediaAsset, tImage, tVideo, Image, Video } from "@/models/media-asset";
import { ISettings } from "@/models/common";

const api = new ApiService();

class MediaAssetApi {
  public async get(
    id: number,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tMediaAsset }
  ): Promise<MediaAsset> {
    return await api.get(`/media-asset/${id}`, settings);
  }

  public async uploadImage(
    params: FormData,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tImage }
  ): Promise<Image> {
    return await api.post("/media-asset/upload/image", params, settings);
  }

  public async uploadVideo(
    params: FormData,
    settings: ISettings = { resultKeyOnSuccess: "data", tModel: tVideo }
  ): Promise<Video> {
    return await api.post("/media-asset/upload/video", params, settings);
  }
}

export default new MediaAssetApi();
