<template>
  <div class="tab-buttons">
    <perfect-scrollbar ref="scroll" class="tab-button" @mousemove="onMouseMove">
      <div
        v-for="(item, index) in options"
        :key="index"
        class="item"
        :class="getItemClasses(item.code)"
        @click="onClickItem(item.code)"
      >
        {{ item.label }}
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script>
export default {
  name: "TabButtons",

  props: {
    value: {
      type: [String, Boolean],
      default: "",
    },

    options: {
      type: Array,
      require: true,
      default: () => [
        // {
        //   label: "",
        //   code: "",
        // },
      ],
    },
  },

  computed: {
    activeItem: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {
    onClickItem(value) {
      if (!this.disabled) this.activeItem = value;
    },

    getItemClasses(value) {
      return { active: this.activeItem === value };
    },

    onMouseMove() {
      this.$refs.scroll.update();
    },
  },
};
</script>

<style lang="postcss" scoped>
.tab-buttons {
  @apply whitespace-nowrap;

  .tab-button {
    @apply flex w-full justify-between border-b;

    .item {
      @apply flex w-full cursor-pointer items-center justify-center;
      @apply text-base text-pale-sky;
      @apply p-2.5;

      &.active {
        @apply border-b-2 border-jaffa-1;
        @apply text-lg font-bold;
      }
    }
  }
}
</style>
