import { Fancybox } from "@fancyapps/ui";

export function isExistSlot(slotName, slots) {
  return !!slots[slotName];
}

export function clientTimezone() {
  return -(new Date().getTimezoneOffset() / 60);
}

export function openFancybox(items, index = 0) {
  Fancybox.show(items, {
    animated: true,
    startIndex: index,
    dragToClose: false,
    Image: {
      click: false,
      wheel: "slide",
      touch: false,

      zoom: false,
      Panzoom: {
        zoom: false,
      },
    },

    Toolbar: {
      display: [{ id: "counter", position: "left" }, "slideshow", "thumbs", "fullscreen", "close"],
    },

    on: {
      initCarousel: (instance) => {
        instance.Carousel.options.friction = 0.95;
      },
    },
  });
}
