export const aggregates = [
  {
    code: "ssp",
    numericCode: 1,
  },
  {
    code: "sitelist",
    numericCode: 2,
  },
  {
    code: "sspDomains",
    numericCode: 3,
  },
  {
    code: "geo",
    numericCode: 4,
  },
  {
    code: "sspGeo",
    numericCode: 6,
  },
  {
    code: "domainsGeo",
    numericCode: 7,
  },
];
