import i18n from "@/plugins/vue-i18n";

const Creatives = () => import(/* webpackChunkName: "adminCreatives" */ "@/views/advert/creatives");

const CreativeEdit = () =>
  import(/* webpackChunkName: "creatives" */ "@/views/advert/creatives/edit");

const creatives = [
  {
    path: "/creatives",
    name: "Creatives",
    component: Creatives,
    meta: {
      title: i18n.t("title.creatives"),
    },
  },
  {
    path: "/creatives/:id/edit",
    name: "CreativeEdit",
    component: CreativeEdit,
    meta: {
      title: i18n.t("title.creative"),
    },
  },
];

export default creatives;
