import i18n from "@/plugins/vue-i18n";

const GlobalBlacklist = () =>
  import(/* webpackChunkName: "global-blacklist" */ "../../../views/admin/global-blacklist");
const GlobalBlacklistAdd = () =>
  import(/* webpackChunkName: "global-blacklist" */ "@/views/admin/global-blacklist/add");

const blacklist = [
  {
    path: "global-blacklist/:type?",
    name: "globalBlacklistAdmin",
    component: GlobalBlacklist,
    meta: {
      title: i18n.t("title.globalBlacklist"),
    },
  },
  {
    path: "global-blacklist/add/:type?",
    name: "globalBlacklistAddAdmin",
    component: GlobalBlacklistAdd,
    meta: {
      title: i18n.t("title.globalBlacklist"),
    },
  },
];

export default blacklist;
