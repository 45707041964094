import * as t from "io-ts";

export interface IRefreshAuthResponse {
  access_token: string;
  expires_in: number;
}

export interface ISendToHubSpotParams {
  submittedAt: number;
  fields: Array<{ name: string; value: string }>;
  context: {
    hutk: string;
    pageUri: string;
    pageName: string;
  };
}

export const tLogin = t.strict({
  access_token: t.string,
  token_type: t.string,
  expires_in: t.string,
});

export const tLoginParams = t.strict({
  email: t.string,
  password: t.string,
});

export const tResetPasswordParams = t.strict({
  email: t.string,
});

export const tCheckEmailParams = t.strict({
  email: t.string,
});

export const tSignUpParams = t.strict({
  firstName: t.string,
  lastName: t.string,
  email: t.string,
  password: t.string,
  password_confirmation: t.string,
  country: t.string,
  company: t.string,
  phone: t.string,
  recaptcha: t.string,
  telegram: t.string,
  skype: t.string,
});

export const tCheckEmail = t.strict({
  emailAlreadyExists: t.boolean,
});

export type Login = t.TypeOf<typeof tLogin>;
export type LoginParams = t.TypeOf<typeof tLoginParams>;
export type ResetPassword = t.TypeOf<typeof tResetPasswordParams>;
export type SignUpParams = t.TypeOf<typeof tSignUpParams>;
export type CheckEmailParams = t.TypeOf<typeof tCheckEmailParams>;
export type CheckEmailResponse = t.TypeOf<typeof tCheckEmail>;
