<template>
  <div :key="appKey" class="app">
    <Loader v-if="isLoading" />

    <Notify />

    <router-view />

    <vue-progress-bar />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { Loader, Notify } from "@/components";

export default {
  name: "App",

  components: {
    Loader,
    Notify,
  },

  data() {
    return {
      adBlockEnabled: false,
    };
  },

  computed: {
    ...mapState("loader", ["isLoading"]),
    ...mapState("app", ["appKey"]),
    ...mapGetters("auth", ["isTokenRefreshNeeded"]),
  },

  watch: {
    isTokenRefreshNeeded: {
      handler: "onExpiredAuthToken",
      intermediate: true,
    },

    isLoading: {
      handler: "onChangeIsLoading",
      intermediate: true,
    },
  },

  methods: {
    ...mapActions("auth", ["verifyAuth"]),

    onExpiredAuthToken() {
      this.verifyAuth();
    },

    onChangeIsLoading(value) {
      value ? this.$Progress.start() : this.$Progress.finish();
    },
  },
};
</script>
