import internalServerError from "./InternalServerError";
import notFound from "./NotFound";
import unauthorized from "./Unauthorized";
import restricted from "./RussiaRestricted";

const ErrorLayout = () => import(/* webpackChunkName: "error" */ "@/layouts/error");

const errorLayout = [
  {
    path: "/",
    name: "ErrorLayout",
    component: ErrorLayout,
    redirect: { name: "AdvertLayout" },
    children: [...internalServerError, ...notFound, ...unauthorized, ...restricted],
  },
];

export default errorLayout;
